import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { login } from '../API/Authentication';

import '../Stylesheets/Auth.css';
import '../Stylesheets/Loading.css';
import { useStateValue } from '../Data/StateProvider';
import client from '../services/Client';

function Login (props){
    const [, dispatch] = useStateValue();

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const  handleEmailChange = (event) => {
        setEmail(event.target.value)
      }

    const setAdmin = (selection) => {
      dispatch({
        type: 'SET_IS_ADMIN',
        item: selection
      })
    }

    const  redirectPath = () => {
      console.log("I am  about to redirect the code to the dashboard")
        const locationState = props.location.state;
        const pathname = (
        locationState && locationState.from && locationState.from.pathname
        );
        return pathname || '/';
        };

    const  handlePasswordChange = (event) => {
        setPassword(event.target.value)
      }

    const submitLogin = async (event) => {
        event.preventDefault();
        var loginsuccess = await login(email, password)
        if (loginsuccess){
            var data = await client.getStatus()
            setAdmin(data.data.admin)
            console.log(data.data.admin)
            setShouldRedirect(true)
        }
      }

    if (shouldRedirect) {
        return (
          <Redirect to={redirectPath()}/>
          );
        } else {
        return (
            <div className="auth-items">
                <div id="auth-items__form">
                    <h2 id="auth-header">Login Here</h2>
                    <form className="auth-items__form-view" id="auth-form" onSubmit={submitLogin}>
                        <label className='email'>
                            <input type="email" placeholder="email" name="email" onChange={handleEmailChange} required/>
                        </label>
                        <label className='password'>
                            <input type="password" name="password" placeholder="Password" onChange={handlePasswordChange} required/>
                        </label>
                        {
                        
                        <input type="submit" className="auth-button" value="Login" />
                        }
                    </form>
                </div>
            </div>
        );
      }
}

export default Login;