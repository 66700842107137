import React, {
  useState,
  useEffect
} from 'react';

import ViewItems from "./ViewItems"

import '../../Stylesheets/Dashboard.css';
import {
  addSubject,
  deleteSubject,
  getSubject,
  updateSubject
} from '../../API/Subject';
import {
  getOneClasses
} from '../../API/Class'
import {
  getOneLevel
} from '../../API/Level'
import {
  getOneSpecialty
} from '../../API/Specialty'
import FilterList from '../../Components/FilterList/FilterList';
import {
  useStateValue
} from '../../Data/StateProvider'

const AddSubject = props => {

  const [{
    selectedSystem,
    selectedClass,
    selectedLevel,
    selectedSpecialty,
    selectedSubject,
  }, dispatch] = useStateValue();

  const [name, setName] = useState("")
  const [subjects, setSubjects] = useState([])
  const [parent, setParent] = useState({})

  useEffect(() => {
    (async function(){
          var subjects = await getSubject(selectedClass._id, selectedLevel._id, selectedSpecialty._id)
          setSubjects(subjects.data)
          if(subjects.data) {
            var result;
            if(typeof selectedSpecialty._id !== 'undefined'){
              result = await getOneSpecialty(selectedSpecialty._id)
              setParent(result.data)
              return
            }
            if(typeof selectedLevel._id !== 'undefined'){
              result = await getOneClasses(selectedLevel._id)
              setParent(result.data)
              return
            }
            if(typeof selectedClass._id  !== 'undefined'){
              result = await getOneLevel(selectedClass._id)
              setParent(result.data)
              return
          }
        }
        })();
    }, [selectedClass, selectedLevel, selectedSpecialty])


  const handleChange = (event) => {
    setName(event.target.value)
  }


  const setSelectedSubject = (selection) => {
    dispatch({
      type: 'SET_SELECTED_SUBJECT',
      item: selection
    })
  }

  const contentTypeClickHandler = async(contentType) => {
    setSelectedSubject(contentType)
    if(contentType._id) {
      props.setCategories({
          'name': 'Video',
          '_id': 'VIDEO'
        }
      )   
    }
  }

  const submitSubject = async (event) => {
    event.preventDefault();
    var parentId = ""
    if (selectedSpecialty._id){
      parentId = selectedSpecialty._id
    }
    if (selectedLevel._id && !selectedSpecialty._id){
      parentId = selectedLevel._id
    }
    if (selectedClass._id && !selectedLevel._id && !selectedSpecialty._id){
      parentId = selectedClass._id
    }
    
    await addSubject(name, selectedSystem._id, parentId)
    var subjects = await getSubject(selectedClass._id, selectedLevel._id, selectedSpecialty._id)
    setSubjects(subjects.data)
    document.getElementById("add-subject-form").reset();
  }

  const updateChild = async (id, rank, name) => {
    await updateSubject(id, name, rank)
    var subjects = await getSubject(selectedClass._id, selectedLevel._id, selectedSpecialty._id)
    setSubjects(subjects.data)
  }

  const deleteAction = async (id) => {
    if (window.confirm('are you sure you want to delete the Education?')) {
      await deleteSubject(id)
      var subjects = await getSubject(selectedClass._id, selectedLevel._id, selectedSpecialty._id)
      setSubjects(subjects.data)
    }
  }

  return (
    <div className="add-items">
      <h3 className="parentName">{parent?parent.name:''}</h3>
      <FilterList activeFilters={[selectedSubject]}
                  filters={subjects}
                  clicked={contentTypeClickHandler }/>
      <ViewItems 
        items={subjects}
        deleteAction = {deleteAction}
        updateChild={updateChild}
      />
      <div id="add-items__form">
        <h2>Add a New Subject</h2>
        <form className="add-items__form-view" id="add-subject-form" onSubmit={submitSubject}>
          <label>
            <span>Subject</span>
            <input type="text" name="name" onChange={handleChange}/>
          </label>
          <input type="submit" className="button" value="Submit" />
        </form>
      </div>
    </div>
  );
}

export default AddSubject;