import './CoursesView.scss';
import CourseView from './CourseView/CourseView';

const coursesView = props => {
    var viewCond = props.viewType !== 'video' && props.viewType !== 'vidéo'
    let coursesList = (
        <div
            className={["CoursesView", viewCond || props.advertVideo ? 'CoursesView--no-split' : 'CoursesView--split'].join(' ')}>
            { props.courses.length > 0 ? ( props.courses.map(course => <CourseView key={course._id} isSelected={props.playingVideo.link === course.link}
                                                            clicked={() => props.clicked(course)}
                                                            closed={props.closeCourse}
                                                            course={course}
                                                            showDelete={props.showDelete}
                                                            viewType={props.viewType}
                                                            delete={() => props.delete(course._id)}
                                                            update={props.update}
                                                            />)): 
            !props.advertVideo && ( props.viewType === 'video' ? <p>Check again later, videos will be available soon...</p> : <p>Revenez plus tard, les vidéos seront bientôt disponibles...</p>)
                                                            }
        </div>
    )
    return coursesList;
}

export default coursesView;