import React, {
    useState,
    useEffect
} from 'react';

import ViewItems from "./ViewItems"

import '../../Stylesheets/Dashboard.css';
import {
    addNews,
    deleteNews,
    getNews,
    updateNews
} from '../../API/News';
import {
    getOneSubSystems
} from '../../API/Subsystem';
import {
    useStateValue
} from '../../Data/StateProvider'

function AddNews() {

    const [{
        selectedSystem,
        isAdmin
    }, dispatch] = useStateValue();

    const [name, setName] = useState("")
    const [news, setNews] = useState([])
    const [parent, setParent] = useState({})

    useEffect(() => {
        (async function(){
            if(selectedSystem._id) {
                var news = await getNews(selectedSystem._id)
                var result = await getOneSubSystems(selectedSystem._id)
                setParent(result.data)
                setNews(news.data)
            }
        })();
    }, [selectedSystem])


    const submitEducation = async (event) => {
        event.preventDefault();
        var news = await addNews(name, selectedSystem._id)
        document.getElementById("add-news-form").reset();
        setNews(news.data)
    }

    const handleChange = (event) => {
        setName(event.target.value)
    }


    const updateChild = async (id, rank, name) => {
        await updateNews(id, rank, name, selectedSystem._id)
        var news = await getNews(selectedSystem._id)
        setNews(news.data)
    }

    const deleteAction = async (id) => {
        if (window.confirm('are you sure you want to delete the Education?')) {
            await deleteNews(id)
            var news = await getNews(selectedSystem._id);
            setNews(news.data)
        }
    }

    return (
        <div className="add-items">
            <h3 className="parentName">{parent.name}</h3>
            {isAdmin && (<><ViewItems 
            items={news}
            deleteAction = {deleteAction}
            updateChild={updateChild}
            />
            <div id="add-items__form">
            <form className="add-items__form-view" id="add-news-form" onSubmit={submitEducation}>
                <label>
                <span>News Type</span>
                <input type="text" name="name" onChange={handleChange}/>
                </label>
                <input type="submit" className="button" value="Submit" />
            </form>
            </div></>)}
        </div>
    );
    
}

export default AddNews;