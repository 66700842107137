import React, { Component } from 'react';
import './TabItem.scss';

class TabItem extends Component {
    render() {
        return (
            <div onClick={typeof this.props.subTabs !== "undefined" ? undefined: () => this.props.changeTab(this.props.children)} className={['TabItem', this.props.active ? 'TabItem--active' : null].join(' ')}>
                <div className="TabItem__title">
                    <span>{this.props.children.name}</span>
                    {this.props.subTabs?this.props.subTabs.map((tab)=>{
                        if(tab.name === this.props.activeTab.name){
                        return <span className="TabItem__title__sub">{tab.name.toUpperCase()}</span>
                        }
                        return <></>;
                    }):(<></>)}
                </div>
                {this.props.subTabs
                    ? (<div className="TabItem__sub">
                        {this.props.subTabs
                            .map(item => <div onClick={() => this.props.changeTab(item)} key={item} className="TabItem__sub--item"><span>{item.name.toUpperCase()}</span></div>)}</div>)
                    : null}
            </div>
        );
    }
}


export default TabItem;