import $ from 'jquery';


export function getClasses(id) {
  
  var url = id ? `/classes?parentId=${id}` : "/classes"
  return $.ajax({
    url: url, //TODO: update request URL
    type: "GET",
    success: (result) => {
      
      return result.data;
    },
    error: (error) => {
      alert('Unable to load Classes. Please try your request again')
      
      return null;
    }
  })
}

export function getOneClasses(id) {
  return $.ajax({
      url: `/classes/${id}`, //TODO: update request URL
      type: "GET",
      success: (result) => {
          
          return result.data;
      },
      error: (error) => {
          alert('Unable to load classes. Please try your request again')
          
          return null;
      }
  })
}

export function addClass(name, rank, subsystem_id, education_id, sub_education_id) {
  
  var parentList = sub_education_id ? [education_id, sub_education_id] : [education_id]
  return $.ajax({
    url: `/classes`, //TODO: update request URL
    type: "POST",
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify({
      name: name,
      rank: 10000,
      subsystem_id: subsystem_id,
      parent_id: parentList
    }),
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    success: (result) => {
      
      return;
    },
    error: (error, strStatus, err) => {
      alert(error.responseText.split('p>')[1])
      
      return;
    }
  })
}

export function updateClass(id, name, rank) {
  
  return $.ajax({
    url: `/classes/${id}`, //TODO: update request URL
    type: "PUT",
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify({
      name: name,
      rank: rank,
    }),
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    success: (result) => {
      alert(result.message)
      
      return result.data;
    },
    error: (error) => {
      
      alert(error.responseText.split('p>')[1])
      
      return;
    }
  })
}

export function deleteClass(id) {
  
  return $.ajax({
    url: `/classes/${id}`, //TODO: update request URL
    type: "DELETE",
    success: (result) => {
      alert("Successfully deleted")
      
      return;
    },
    error: (error) => {
      alert(error.responseText.split('p>')[1])
      
      return;
    }
  })
}