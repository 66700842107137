import React, {
    Component
} from 'react';
import '../../Stylesheets/ViewItems.css';

class ViewItems extends Component {
    constructor() {
        super();
        this.state = {
            items: null,
            item_id: 0,
            item_rank: "",
            item_name: ''
        }
    }
    componentWillMount() {
        this.setState({
            items: this.props.items
        })
        this.items = this.props.items
    }

    handleChange = (id, rank, event) => {
        // console.log(this.state.rank)
        // console.log("printing the rank")
        this.setState({
            item_name: event.target.value,
            item_id: id,
            item_rank: this.state.item_rank !== "" && typeof this.state.item_rank !== "undefined" ? this.state.rank : rank
        })
    }

    handleRankChange = (id, name, event) => {
        // console.log("in thhe first")
        // console.log(`${id} and ${event.target.value} and ${name}`)
        name = typeof this.state.item_name == "undefined" || this.state.item_name === "" ? name : this.state.item_name;
        this.setState({
            item_rank: event.target.value,
            item_id: id,
            item_name: name
        })
    }

    submitUpdate = (event) => {
        event.preventDefault();
        // console.log("in the first")
        // console.log(`${this.state.item_id} and ${this.state.item_rank} and ${this.state.item_name}`)
        this.props.updateChild(this.state.item_id, this.state.item_rank, this.state.item_name)
        // this.setState({
        //     item_id: 0,
        //     item_name: 0,
        //     item_rank: 0
        // })
        // document.getElementById("edit-video-form").reset();
    }

    render() {
        const {
            items
        } = this.props;
        this.items = items
        console.log("this items now")
        console.log(this.items)
        return (
            <div className="view-holder">
              { this.items !== null && <div id="view-items" className="view-holder__view-items">
                <ul >
                  {typeof this.items !== "undefined" && this.items.map((item, ind) => (
                    <li key={item['_id']} className="view-holder__list-item">
                      <form className="edit-items__form-view" id="edit-video-form" onSubmit={this.submitUpdate}>
                        <label>
                          <input type="text" placeholder={item['name']} name="item_name" onChange={this.handleChange.bind(this, item['_id'], item['rank'])} />
                        </label>
                        <label >
                          <select name="rank_id" onChange={this.handleRankChange.bind(this, item['_id'], item['name'])}>
                              <option value={0}>{item['rank']}</option>
                              {this.items && this.items.map((item, ind) => (
                              <option key={`${item['name']+item['_id']}`} value={ind+1}>
                                  {ind+1}
                              </option>
                              ))}
                          </select>
                        </label>
                        <input type="submit" className="button" value="update" />
                      </form>
                      <div className=" view-holder__delete" onClick={() => this.props.deleteAction(item._id)}>
                      <svg className="icon-bin">
                          <use xlinkHref="./icons/symbol-defs.svg#icon-bin"></use>
                      </svg>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>}
            </div>
        );
    }
}

export default ViewItems;