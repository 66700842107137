import $ from 'jquery';


export function getSubject(class_id, level_id, specialty_id) {
    class_id = typeof class_id === 'undefined' ? null : class_id
    level_id = typeof level_id === 'undefined' ? null : level_id
    specialty_id = typeof specialty_id === 'undefined' ? null : specialty_id
    
    var url = "/subject"
    if (specialty_id && level_id && class_id){
      url = `/subject?specialty_id=${specialty_id}&level_id=${level_id}&class_id=${class_id}`
    }

    if (level_id && class_id && !specialty_id){
      url = `/subject?level_id=${level_id}&class_id=${class_id}`
    }

    if (class_id && !specialty_id && !level_id){
      url = `/subject?class_id=${class_id}`
    }
    
    return $.ajax({
        url: url, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load specialtys. Please try your request again')
            
            return null;
        }
    })
}

export function getOneSubject(id) {
  return $.ajax({
      url: `/subject/${id}`, //TODO: update request URL
      type: "GET",
      success: (result) => {
          
          return result.data;
      },
      error: (error) => {
          alert('Unable to load subject. Please try your request again')
          
          return null;
      }
  })
}

export function addSubject(name, subsystem_id, parent_id) {
  
    return $.ajax({
        url: `/subject`, //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          rank: 1000,
          subsystem_id: subsystem_id,
          parent_id: parent_id
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          
          return;
        },
        error: (error) => {
          alert('Unable to add sub educations. Please try your request again')
          
          return;
        }
      })
}

export function updateSubject(id, name, rank, parent_id) {
  
    return $.ajax({
        url: `/subject/${id}`, //TODO: update request URL
        type: "PUT",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          rank: rank,
          parent_id: parent_id
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            alert(result.message)
            
            return result.data;
        },
        error: (error) => {
          alert('Unable to add sub educations. Please try your request again')
          
          return;
        }
      })
}

export function deleteSubject(id) {
  
    return $.ajax({
        url: `/subject/${id}`, //TODO: update request URL
        type: "DELETE",
        success: (result) => {
            alert("Successfully deleted")
            
            return;
        },
        error: (error) => {
            alert('Unable to Delete systems. Please try your request again')
            
            return;
        }
    })
}