import $ from 'jquery';


export function getLevel(id) {
    
    var url = id ? `/level?parentId=${id}` : "/level"
    return $.ajax({
        url: url, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load Educations. Please try your request again')
            
            return null;
        }
    })
}

export function getOneLevel(id) {
    return $.ajax({
        url: `/level/${id}`, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load level. Please try your request again')
            
            return null;
        }
    })
  }

export function addLevel(name, rank, subsystem_id, class_id) {
    
    return $.ajax({
        url: `/level`, //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
            name: name,
            rank: 10000,
            subsystem_id: subsystem_id,
            parent_id: class_id
        }),
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            
            return;
        },
        error: (error) => {
            alert('Unable to add sub educations. Please try your request again')
            
            return;
        }
    })
}

export function updateLevel(id, name, rank) {
    
    return $.ajax({
        url: `/level/${id}`, //TODO: update request URL
        type: "PUT",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
            name: name,
            rank: rank,
        }),
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            alert(result.message)
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to add sub educations. Please try your request again')
            
            return;
        }
    })
}

export function deleteLevel(id) {
    
    return $.ajax({
        url: `/level/${id}`, //TODO: update request URL
        type: "DELETE",
        success: (result) => {
            alert("Successfully deleted")
            
            return;
        },
        error: (error) => {
            alert('Unable to Delete systems. Please try your request again')
            
            return;
        }
    })
}