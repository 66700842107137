import './SystemItem.scss';
import TabItem from './SystemItems/SystemItem';

const systemItems = props => {
    return <div className='SystemItems Min-goal'>
        {props.tabs.map(tab => {
            let  tabItem = (
                    <TabItem
                        key={tab}
                        changeTab={() => props.changeTab(tab)}
                        active={props.activeTab.name === tab.name}>
                        {tab}
                    </TabItem>)
            return tabItem;
        })}
    </div>
}

export default systemItems;