import client from '../services/Client'

export const initialState = {
    showRegister: false,
    selectedCategory: "SYSTEMS",
    isLoading: false,
    selectedSystem: {},
    selectedEducation: {},
    selectedSubEducation: {},
    selectedClass: {},
    selectedExam: {},
    selectedLevel: {},
    selectedSpecialty: {},
    selectedSubject: {},
    selectedChoice: "Normal",
    isAdmin: false,
}

function reducer (state, action) {
    switch(action.type) {
        case 'ADD_USER':
            client.setDataAndToken(action.item)
            return {
                ...state,
                user: action.item
            }
        case 'UPDATE_SELECTED':
            return {
                ...state,
                selectedCategory: action.item
            }
        case 'SET_LOADER':
            return {
                ...state,
                isLoading: action.item
            }
        case 'SET_SELECTED_SYSTEM':
            return {
                ...state,
                selectedSystem: action.item,
                selectedEducation: {},
                selectedSubEducation: {},
                selectedClass: {},
                selectedLevel: {},
                selectedSpecialty: {},
                selectedSubject: {}
            }
        case 'SET_SELECTED_EDUCATION':
            return {
                ...state,
                selectedEducation: action.item,
                selectedSubEducation: {},
                selectedClass: {},
                selectedLevel: {},
                selectedSpecialty: {},
                selectedSubject: {}
            }
        case 'SET_SELECTED_SUB_EDUCATION':
            return {
                ...state,
                selectedSubEducation: action.item,
                selectedClass: {},
                selectedLevel: {},
                selectedSpecialty: {},
                selectedSubject: {}
            }
        case 'SET_SELECTED_CLASS':
            return {
                ...state,
                selectedClass: action.item,
                selectedLevel: {},
                selectedSpecialty: {},
                selectedSubject: {}
            }
        case 'SET_SELECTED_EXAM':
            return {
                ...state,
                selectedExam: action.item,
            }
        case 'SET_SELECTED_LEVEL':
            return {
                ...state,
                selectedLevel: action.item,
                selectedSpecialty: {},
                selectedSubject: {}
            }
        case 'SET_SELECTED_SPECIALTY':
            return {
                ...state,
                selectedSpecialty: action.item,
                selectedSubject: {}
            }
        case 'SET_SELECTED_SUBJECT':
            return {
                ...state,
                selectedSubject: action.item,
            }
        case 'SET_SELECTED_CHOICE':
            return {
                ...state,
                selectedChoice: action.item,
            }
        case 'SET_IS_ADMIN':
            console.log("setting is admin")
            console.log(action.item)
            return {
                ...state,
                isAdmin: action.item,
            }
        default:
            return state;
    }
}

export default reducer