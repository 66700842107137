import $ from 'jquery';


export function getSubSystems() {
    
    return $.ajax({
        url: `/subsystem`, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load sub_systems. Please try your request again')
            
            return null;
        }
    })
}

export function getOneSubSystems(id) {
    return $.ajax({
        url: `/subsystem/${id}`, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load subsystem. Please try your request again')
            
            return null;
        }
    })
  }

export function updateSubsystem(id, rank, name) {
    
    return $.ajax({
        url: `/subsystem/${id}`, //TODO: update request URL
        type: "PUT",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
            name: name,
            id: id,
            rank: rank,
        }),
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            alert(result.message)
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to add systems. Please try your request again')
            
            return null;
        }
    })
}

export function addSubsystem(rank, name) {
    
    return $.ajax({
        url: '/subsystem', //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
            name: name,
            rank: 10000,
        }),
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to add systems. Please try your request again')
            
            return;
        }
    })
}

export function deleteSubsystem(id) {
    
    return $.ajax({
        url: `/subsystem/${id}`, //TODO: update request URL
        type: "DELETE",
        success: (result) => {
            
            return ;
        },
        error: (error) => {
            alert('Unable to Delete systems. Please try your request again')
            
            return null;
        }
    })
}