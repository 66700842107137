import $ from 'jquery';


export function getEducation(id) {
    
    var url = id ? `/education?subsystem_id=${id}` : "/education"
    return $.ajax({
        url: url, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            
            alert('Unable to load Educations. Please try your request again')
            return null;
        }
    })
}

export function getOneEducation(id) {
  return $.ajax({
      url: `/education/${id}`, //TODO: update request URL
      type: "GET",
      success: (result) => {
          
          return result.data;
      },
      error: (error) => {
          alert('Unable to load sub_educations. Please try your request again')
          
          return null;
      }
  })
}

export function addEduction(name, subsystem_id) {
    
    return $.ajax({
        url: `/education`, //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          rank: 1000,
          subsystem_id: subsystem_id,
          parent_id: subsystem_id
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          
          return;
        },
        error: (error) => {
          alert('Unable to add educations. Please try your request again')
          
          return;
        }
      })
}

export function updateEducation(id, rank, name, subsystem_id) {
    
    return $.ajax({
        url: `/education/${id}`, //TODO: update request URL
        type: "PUT",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          rank: rank,
          subsystem_id: subsystem_id,
          parent_id: subsystem_id
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            alert(result.message)
            
            return result.data;
        },
        error: (error) => {
          alert('Unable to add educations. Please try your request again')
          
          return;
        }
      })
}

export function deleteEducation(id) {
    
    return $.ajax({
        url: `/education/${id}`, //TODO: update request URL
        type: "DELETE",
        success: (result) => {
            alert("Successfully deleted")
            
            return;
        },
        error: (error) => {
            alert('Unable to Delete systems. Please try your request again')
            
            return;
        }
    })
}