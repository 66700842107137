import React, {
  useState,
  useEffect
} from 'react';

import ViewItems from "./ViewItems"

import '../../Stylesheets/Dashboard.css';
import {
  addLevel,
  deleteLevel,
  getLevel,
  updateLevel
} from '../../API/Level';
import {
  getOneClasses,
} from '../../API/Class';
import FilterList from '../../Components/FilterList/FilterList';
import {
  useStateValue
} from '../../Data/StateProvider';
import {
  getSpecialty,
} from '../../API/Specialty';
import {
  getSubject,
} from '../../API/Subject';

const AddLevel = (props) =>{
  const [{
    selectedSystem,
    selectedClass,
    selectedLevel,
    isAdmin,
  }, dispatch] = useStateValue();

  const [name, setName] = useState("")
  const [categories, setCategories] = useState([])
  const [parent, setParent] = useState({})
  
  useEffect(() => {
    (async function(){
        if(typeof selectedClass._id !== 'undefined' ) {
            var levels = await getLevel(selectedClass._id)
            setCategories(levels.data)
            if (levels.data.length > 0){
              var result = await getOneClasses(selectedClass._id)
              setParent(result.data)
            }
        }
        })();
    }, [selectedClass])

  const submitLevel = async (event) => {
    event.preventDefault();
    var levels = await addLevel(name, 0, selectedSystem._id, selectedClass._id)
    setCategories(levels.data)
    document.getElementById("add-level-form").reset();
  }

  const updateChild = async (id, rank, name) => {
    await updateLevel(id, name, rank)
    var levels =  await getLevel(selectedClass._id)
    setCategories(levels.data)
  }

  const handleChange = (event) => {
    setName(event.target.value)
  }

  const deleteAction = async (id) => {
    if (window.confirm('are you sure you want to delete the Education?')) {
      await deleteLevel(id)
      var levels = await getLevel(selectedClass._id)
      setCategories(levels.data)
    }
  }

  const setSelectedClass = (selection) => {
    dispatch({
      type: 'SET_SELECTED_LEVEL',
      item: selection
    })
  }

  const contentTypeClickHandler = async(contentType) => {
    setSelectedClass(contentType)
        if(contentType._id) {
          var specialties = await getSpecialty(contentType._id)
          var subjects = await getSubject(selectedClass._id, contentType._id)
          console.log(subjects)
          if(specialties.data.length > 0){
              props.setCategories({
                  'name': 'Specialty',
                  '_id': 'SPECIALTY'
                })
          }else{
            if(subjects.data.length > 0){
              props.setCategories({
                  'name': 'Subject',
                  '_id': 'SUBJECT'
                })
          }
          }
        }
  }

  return (
    <div className="add-items">
      <h3 className="parentName">{parent.name}</h3>
      <FilterList activeFilters={[selectedLevel]}
                    filters={categories}
                    clicked={contentTypeClickHandler }/>
      {isAdmin && (<><ViewItems 
        items={categories}
        deleteAction = {deleteAction}
        updateChild={updateChild}
      />
      <div id="add-items__form">
        <h2>Add a New Level Or Cycle</h2>
        <form className="add-items__form-view" id="add-level-form" onSubmit={submitLevel}>
          <label>
            <span>Level Or Cycle</span>
            <input type="text" name="name" onChange={handleChange}/>
          </label>
          <input type="submit" className="button" value="Submit" />
        </form>
      </div></>)}
    </div>
  );
}

export default AddLevel;