import React, {
    useState,
    useEffect
  } from 'react';
  import { CSVLink } from 'react-csv'
  
import '../../Stylesheets/Dashboard.css';
import CoursesView from '../CoursesView/CoursesView';
import {
    addVideo,
    deleteVideo,
    getAllVideos,
    updateVideo
} from '../../API/Video';
import FilterList from '../../Components/FilterList/FilterList';
import {
  useStateValue
} from '../../Data/StateProvider'

function AddVideo (){

    const [{
        selectedSystem,
        selectedEducation,
        selectedSubEducation,
        selectedClass,
        selectedLevel,
        selectedSpecialty,
        selectedSubject,
      }, ] = useStateValue();

    const [name, setName] = useState("")
    const [link, setLink] = useState("")
    const [description, setDescription] = useState("")
    const [selectedContentType] = useState('video')
    const [semester] = useState(['first', 'second', 'third', 'revision'])
    const [selectedSemester, setSelectedSemester] = useState('first')
    const [videos, setVideos] = useState([])
    const [playingVideo] = useState({})
    const [parent, setParent] = useState('')
    const [subjectName, setSubjectName] = useState('')
    const [exportCsv, setExportCSV] = useState([])

    useEffect(() => {
      (async function(){
          if(selectedSubject._id) {
            var videos = await getAllVideos(selectedSubject._id, selectedSemester)
            var subsys = typeof selectedSystem.name !== 'undefined' ? selectedSystem.name.toUpperCase() + ' / ' : ''
            var edu = typeof selectedEducation.name !== 'undefined' ? selectedEducation.name.toUpperCase() + ' / ' : ''
            var subEdu = typeof selectedSubEducation.name !== 'undefined' ? selectedSubEducation.name.toUpperCase() + ' / ' : ''
            var classe = typeof selectedClass.name !== 'undefined' ? selectedClass.name.toUpperCase() + ' / ' : ''
            var level = typeof selectedLevel.name !== 'undefined' ? selectedLevel.name.toUpperCase() + ' / ' : ''
            var special = typeof selectedSpecialty.name !== 'undefined' ? selectedSpecialty.name.toUpperCase() + ' / ' : ''
            var subject = typeof selectedSubject.name !== 'undefined' ? selectedSubject.name : ''
            var parentName = typeof selectedSubject.name !== 'undefined' ? subsys+edu+subEdu+classe+level+special+subject : ''
            var export_name = typeof selectedSubject.name !== 'undefined' ? subEdu+classe+level+special+subject : ''
            setParent(parentName)
            export_csv(videos.data, export_name.replaceAll(' / ', '_'))
            setVideos(videos.data)
          }
      })();
  }, [selectedSystem,
    selectedEducation,
    selectedSubEducation,
    selectedClass,
    selectedLevel,
    selectedSpecialty,
    selectedSubject,
    selectedSemester])

    const getVideo = async (id, semester) => {
        var videos = await getAllVideos(id, semester)
        setVideos(videos.data)
    }


    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handleLinkChange = (event) => {
        setLink(event.target.value)
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }
  
    const submitVideo = async (event) => {
      event.preventDefault();
      var data = {
          "name": name,
          "link": link,
          "subsystem_id": selectedSystem._id,
          "description": description,
          "semester": selectedSemester,
          "parent_id": selectedSubject._id
      }
      var videos = await addVideo(data);
      setVideos(videos.data)
      document.getElementById("add-video-form").reset();
    }

    const deleteAction = async (id) => {
        if (window.confirm('are you sure you want to delete the Video?')) {
          await deleteVideo(id)
          getVideo(selectedSubject._id, selectedSemester)
        }
      }

    const update = async (id, name, description, link, active) => {
        console.log(id, name, description, link, active)
        await updateVideo(id, name, description, link, active);
        getVideo(selectedSubject._id, selectedSemester)
      }

  
      const contentTypeClickHandler = contentType => {
        setSelectedSemester(contentType)
      }

      const export_csv = (tobe_exported, sub_export) => {
        let export_data = [];
        tobe_exported.forEach((res) => {
          let object = {title:res.name, lesson_description:res.description, link:res.link, revision:res.revision};
          // console.log(res.name, res.description, res.link, res.revision)
          export_data.push(object)
        })
        setExportCSV(export_data);
        setSubjectName(sub_export);
      }

      return (
        <div className="add-items">
          <h3 className="parentName">{String(parent)}</h3>
          <FilterList activeFilters={[selectedSemester]}
                    filters={semester}
                    clicked={contentTypeClickHandler }/>
          <CoursesView advertVideo={null}
                  viewType={selectedContentType}
                  courses={videos}
                  playingVideo={playingVideo}
                  clicked={()=>{}}
                  closeCourse={null}
                  showDelete={true}
                  delete={deleteAction}
                  update={update}
                  />
            <div id="add-items__form">
              <h2>Add a new Video</h2>
              <form className="add-items__form-view" id="add-video-form" onSubmit={submitVideo}>
                <div className='title__fix'>
                  <label>
                    <span>Name</span>
                  </label>
                  <input type="text" name="name" onChange={handleNameChange} required/>
                </div>
                
                <label>
                  <span>Description</span>
                  <textarea
                    rows="4" cols="30"
                    type="text"
                    className="form-control form-control-lg"
                    name="description"
                    onChange={handleDescriptionChange}
                    required
                  />
                </label>
                <label>
                  <span>Link</span>
                  <input type="url" placeholder="https://www.youtube.com/watch?v=HbAZ6cFxCeY" pattern="https://www.youtube.com/.*" name="link" onChange={handleLinkChange} required/>
                </label>
                <input type="submit" className="button" value="Submit" />
              </form>
              {/* <div><button onClick={() => export_csv(videos)}><span>export</span></button></div> */}
              <div><span><CSVLink data={exportCsv} filename={subjectName} className="export__button">Export CSV</CSVLink></span></div>
            </div>
        </div>
      );
  }
  
  export default AddVideo;