
import React, { useEffect, useState } from 'react';

import '../../Stylesheets/UserView.css';
import { deleteUsers, getUsers } from '../../API/User';

function UserView () {
    const [users, setUsers] = useState(null)
    const [fetchingInProgress, setFetchingInProgress] = useState(false)

      useEffect(() => {
          getAllUsers();
      }, [])

      const getAllUsers = async () =>{
        var users = await getUsers()
        setUsers(users.data)
      }

      const deleteAction = async (id) => {
        if (window.confirm('are you sure you want to delete the Education?')) {
          await deleteUsers(id)
          getAllUsers();
        }
      }

      return (
            <div className="view-user-holder">
                {!users && <div>Ohh shit</div>}
            { users && <div id="view-user-items" className="view-user-holder__view-user-items">
            
            <ul >
                {users.map((item, ind) => (
                <li key={item['_id']} className="view-user-holder__list-item Rtable Rtable--3cols">
                    <form className="edit-items__form-view edit-user__form-view Rtable-cell" id="edit-video-form" onSubmit={()=>{}}>
                    <div className="Rtable-cell"> {item['email']}</div>
                    <div className="Rtable-cell"> {item['registered_on']}</div>
                    <label className="Rtable-cell">
                        <input type="text" placeholder={item['name']} name="item_name" onChange={()=>{}} />
                    </label>
                    <label>
                        <input type="checkbox" defaultChecked={item['admin']} name="item_name" onChange={()=>{}} />
                    </label>
                    <input type="submit" className="button" value="update" disabled/>
                    </form>
                    <img src="./delete.png" alt="delete" className="view-user-holder__delete" onClick={() => deleteAction(item._id)}/>
                </li>
                ))}
            </ul>
            </div>}
            { !users && <div id="view-user-items" className="view-user-holder__view-user-items">
                <h2>No users added</h2>
            </div>}
        </div>
        );
    }

export default UserView