import React, { useState, useEffect } from 'react';

import client from '../services/Client';
import DashboardController from "./DashboardController"
import '../containers/Content/Content.scss'
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import {
  Link
} from 'react-router-dom'

import '../Stylesheets/Dashboard.css';
import '../Stylesheets/App.css';
import FilterList from '../Components/FilterList/FilterList';

import {
  useStateValue
} from '../Data/StateProvider'
import SideMenu from './SideMenu/SideMenu';

function FormView() {

  const [{
    selectedCategory,
    selectedChoice,
  }, dispatch] = useStateValue();

  const [showSideMenu, setShowSideMenu] = useState(false)
  const [standardValues] = useState([{
    'name': 'Video News',
    '_id': 'VIDEO NEWS'
  },{
    'name': 'Manage Users',
    '_id': 'USERS'
  }])

  const [normalCategories] = useState([{
    'name': 'Sub-System Of Education',
    '_id': 'SYSTEMS'
  }, ]);

  const [timetableCategories] = useState([{
    'name': 'Sub-System Of Education',
    '_id': 'SYSTEMS'
  }, {
    'name': 'Education Type',
    '_id': 'EDUCATION'
  }, {
    'name': 'Sub-Education type',
    '_id': 'SUB-CATEGORY'
  }, {
    'name': 'Exams',
    '_id': 'EXAMS'
  }, {
    'name': 'Timetable',
    '_id': 'TIMETABLE'
  }]);

  const [newsCategories] = useState([{
    'name': 'Sub-System Of Education',
    '_id': 'SYSTEMS'
  }, {
    'name': 'NEWS',
    '_id': 'NEWS'
  }]);
  const [categories, setCategories] = useState([]);


  useEffect( ()=> {
    if(selectedChoice === "Timetable"){
      setCategories(timetableCategories)
    }
    if(selectedChoice === "News"){
      setCategories(newsCategories)
    }
    if(selectedChoice === "Normal"){
      setCategories(normalCategories)
    }
  }, [standardValues, selectedChoice, timetableCategories, normalCategories, newsCategories])



  const filteredCategoryList = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i]["_id"] === obj["_id"]) {
            return list.splice(0,i);
        }
    }

    return list;
}

  const [choices,] = useState(["Normal", "Timetable", "News"]);

  const setNormalCategories = (aCategory) =>{
    if(selectedChoice === "Normal"){
      setCategories([...filteredCategoryList(aCategory, categories), aCategory]);
    }
    
  }

  const setSelection = (some_selection) => {
    // console.log(selectedCategory)
    // console.log(some_selection)
    setIsAdmin();
    dispatch({
      type: 'UPDATE_SELECTED',
      item: some_selection._id
    })
  }

  const setSelectedChoice = (selection) => {
    dispatch({
      type: 'SET_SELECTED_CHOICE',
      item: selection
    })
  }

  const setAdmin = (selection) => {
    dispatch({
      type: 'SET_IS_ADMIN',
      item: selection
    })
  }

  const choiceClickHandler = contentType => {
    setSelectedChoice(contentType)
  }

  const sideMenuToggleHandler = () => {
    setShowSideMenu(!showSideMenu)
}

  const setIsAdmin = async () => {
    var data = await client.getStatus()
    setAdmin(data.data.admin)
    // console.log(data.data.admin)
  }
  return (
    <div className="MainView--main">
    <Header/>
    <FilterList activeFilters={[selectedChoice]}
                    filters={choices}
                    clicked={choiceClickHandler }/>
    <main className='MainView__content'>
      <SideMenu changeMenuItem={setSelection} items={[...categories, ...standardValues]} acitveSubMenuItem={selectedCategory} sideMenuToggleHandler={sideMenuToggleHandler} showSideMenu={showSideMenu}/>
      <div className={`MainView__main-view ${showSideMenu?"": "Main__show"}`}>
        <div className="login-route">
        {client.isLoggedIn() ? (
            <Link className='ui item' to='/logout'>
            Logout
            </Link>
          ) : (<></>)
          }
        </div>
        <div className="form-view__item-view">
          <DashboardController setNormalCategories={setNormalCategories} test={"testing by Ulrich"}/>
        </div>
      </div>
    </main>
    <Footer/>
    </div>
  );
}

export default FormView;