import $ from 'jquery';


export function getAllVideos(id, semester) {
    
    var url = `/video?parentId=${id}&semester=${semester}`
    return $.ajax({
        url: url, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load specialtys. Please try your request again')
            
            return null;
        }
    })
}

export function getActiveVideos(id, semester) {
    
    var url = `/video?parentId=${id}&semester=${semester}&active=${true}`
    return $.ajax({
        url: url, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load specialtys. Please try your request again')
            
            return null;
        }
    })
}

export function addVideo(data) {
    
    return $.ajax({
        url: `/video`, //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            
            return;
        },
        error: (error) => {
          alert('Unable to add sub educations. Please try your request again')
          
          return;
        }
      })
}

export function updateVideo(id, name, description, link, active) {
    return $.ajax({
        url: `/video/${id}`, //TODO: update request URL
        type: "PUT",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          description: description,
          link: link,
          active: active
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            alert(result.message)
            return result.data;
        },
        error: (error) => {
          alert('Unable to add sub educations. Please try your request again')
          return;
        }
      })
}

export function deleteVideo(id) {
    
    return $.ajax({
        url: `/video/${id}`, //TODO: update request URL
        type: "DELETE",
        success: (result) => {
            alert("Successfully deleted")
            
            return ;
        },
        error: (error) => {
            alert('Unable to Delete video. Please try your request again')
            
            return null;
        }
    })
}