import React, {
    useState,
    useEffect
  } from 'react';
  import './DateControl.scss';
import "react-datepicker/dist/react-datepicker.css";
import FilterList from '../Components/FilterList/FilterList';


function DateControl(props) {
    const [navigators, setNavigators] = useState(["Previous", "Today", "Next"])
    const [navigatorsEn,] = useState(["Previous", "Today", "Next"])
    const [navigatorsFr,] = useState(["Précédent", "Aujourd'hui", "Suivant"])
    const [selectedNavigator, setSelectedNavigator] = useState("")
    const [selectedDate, setSelectedDate] = useState('')

    useEffect(() => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const language = props.showFrench?'fr-FR': 'en-US';
        var today = new Date()
        var selected = typeof props.selected === 'undefined'? today : props.selected
        props.showFrench?setNavigators(navigatorsFr):setNavigators(navigatorsEn);
        var selectedIndex;
        if(selected.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)){
            selectedIndex = 1;
        }
        if(selected.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)){
            selectedIndex = 2;
        }
        if(selected.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)){
            selectedIndex = 0;
        }
        const todayText = selectedIndex === 1 ? (props.showFrench?"Aujourd'hui":"Today"):''; 
        setSelectedNavigator(props.showFrench?navigatorsFr[selectedIndex]:navigatorsEn[selectedIndex])
        setSelectedDate(todayText + ' ' + selected.toLocaleDateString(language, options))
    }, [navigatorsEn, navigatorsFr, props.showFrench, props.selected])

    const navigatorClickHandler = (chosenNavigator) => {
        setSelectedNavigator(chosenNavigator)
        var newDate = new Date()
        var selected = typeof props.selected === 'undefined'? newDate : props.selected
        if(chosenNavigator === navigators[0]){
            newDate = new Date(selected.setDate(selected.getDate() - 1))
        }
        if(chosenNavigator === navigators[2]){
            newDate = new Date(selected.setDate(selected.getDate() + 1))
        }
        props.onSelect(newDate)
    }

    return (
        <div className="date-control">
            <FilterList activeFilters={[selectedNavigator]}
                        filters={navigators}
                        clicked={navigatorClickHandler}
            />
            <span>{selectedDate}</span>
        </div>
    )
}

export default DateControl
