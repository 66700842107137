import $ from 'jquery';


export function getExams(id) {
  
  var url = id ? `/exams?parentId=${id}` : "/exams"
  return $.ajax({
    url: url, //TODO: update request URL
    type: "GET",
    success: (result) => {
      
      return result.data;
    },
    error: (error) => {
      alert('Unable to load exams. Please try your request again')
      
      return null;
    }
  })
}

export function getOneExams(id) {
  return $.ajax({
      url: `/exams/${id}`, //TODO: update request URL
      type: "GET",
      success: (result) => {
          
          return result.data;
      },
      error: (error) => {
          alert('Unable to load exams. Please try your request again')
          
          return null;
      }
  })
}

export function addExams(name, rank, subsystem_id, education_id, sub_education_id) {
  var parentList = sub_education_id ? [sub_education_id] : [education_id]
  return $.ajax({
    url: `/exams`, //TODO: update request URL
    type: "POST",
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify({
      name: name,
      rank: 10000,
      subsystem_id: subsystem_id,
      parent_id: parentList
    }),
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    success: (result) => {
      
      return;
    },
    error: (error) => {
      alert('Unable to add sub educations. Please try your request again')
      
      return;
    }
  })
}

export function updateExams(id, name, rank) {
  
  return $.ajax({
    url: `/exams/${id}`, //TODO: update request URL
    type: "PUT",
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify({
      name: name,
      rank: rank,
    }),
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    success: (result) => {
      alert(result.message)
      
      return result.data;
    },
    error: (error) => {
      alert('Unable to add sub educations. Please try your request again')
      
      return;
    }
  })
}

export function deleteExams(id) {
  
  return $.ajax({
    url: `/exams/${id}`, //TODO: update request URL
    type: "DELETE",
    success: (result) => {
      alert("Successfully deleted")
      
      return;
    },
    error: (error) => {
      
      alert('Unable to Delete systems. Please try your request again')
      return;
    }
  })
}