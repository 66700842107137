import React, {
  useState,
  useEffect
  }
  from 'react';

  import ViewItems from "./ViewItems"

  import '../../Stylesheets/Dashboard.css';
  import {
    addSpecialty,
    deleteSpecialty,
    getSpecialty,
    updateSpecialty
  } from '../../API/Specialty';
  import {
    getOneLevel
  } from '../../API/Level'
  import FilterList from '../../Components/FilterList/FilterList';
  import {
    useStateValue
  } from '../../Data/StateProvider'
  import {
    getSubject,
  } from '../../API/Subject';

const AddSpecialty = (props) =>{

  const [{
    selectedSystem,
    selectedClass,
    selectedLevel,
    selectedSpecialty,
    isAdmin,
  }, dispatch] = useStateValue();

  const [name, setName] = useState("")
  const [specialties, setSpecialties] = useState([])
  const [parent, setParent] = useState({})

  useEffect(() => {
    (async function(){
        if(typeof selectedLevel._id !== 'undefined' ) {
            console.log(selectedLevel._id)
            var specialties = await getSpecialty(selectedLevel._id)
            setSpecialties(specialties.data)
            if(specialties.data) {
              var result = await getOneLevel(selectedLevel._id)
              setParent(result.data)
            }
        }
        })();
    }, [selectedLevel])

  const submitSpecialty = async (event) => {
    event.preventDefault();
    var specialties = await addSpecialty(name, selectedSystem._id, selectedLevel._id)
    setSpecialties(specialties.data)
    document.getElementById("add-specialty-form").reset();
  }

  const handleChange = (event) => {
    setName(event.target.value)
  }

  const updateChild = async (id, rank, name) => {
    updateSpecialty(id, name, rank)
    var specialties = await getSpecialty(selectedLevel._id)
    setSpecialties(specialties.data)
  }

  const deleteAction = async (id) => {
    if (window.confirm('are you sure you want to delete the Education?')) {
      await deleteSpecialty(id)
      var specialties = await getSpecialty(selectedLevel._id)
      setSpecialties(specialties.data)
    }
  }

  const setSelectedSpecialty = (selection) => {
    dispatch({
      type: 'SET_SELECTED_SPECIALTY',
      item: selection
    })
  }

  const contentTypeClickHandler = async(contentType) => {
    setSelectedSpecialty(contentType)
        if(contentType._id) {
          var subjects = await getSubject(selectedClass._id, selectedLevel._id, contentType._id)
              if(subjects.data.length > 0){
                props.setCategories({
                    'name': 'Subject',
                    '_id': 'SUBJECT'
                  })
            }
        }
  }

  return (
    <div className="add-items">
    <h3 className="parentName">{parent?parent.name:''}</h3>
      <FilterList activeFilters={[selectedSpecialty]}
                    filters={specialties}
                    clicked={contentTypeClickHandler }/>
      {isAdmin && (<><ViewItems 
        items={specialties}
        deleteAction = {deleteAction}
        updateChild={updateChild}
      />
      <div id="add-items__form">
        <h2>Add a New Class</h2>
        <form className="add-items__form-view" id="add-specialty-form" onSubmit={submitSpecialty}>
          <label>
            <span>Class</span>
            <input type="text" name="name" onChange={handleChange}/>
          </label>
          <input type="submit" className="button" value="Submit" />
        </form>
      </div></>)}
    </div>
  );
}

export default AddSpecialty;