import $ from 'jquery';


export function getSubEducation(id) {
    
    var url = id ? `/sub_education?education_id=${id}` : "/sub_education"
    return $.ajax({
        url: url, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load sub_educations. Please try your request again')
            
            return null;
        }
    })
}

export function getOneSubEducation(id) {
  return $.ajax({
      url: `/sub_education/${id}`, //TODO: update request URL
      type: "GET",
      success: (result) => {
          
          return result.data;
      },
      error: (error) => {
          alert('Unable to load sub_educations. Please try your request again')
          
          return null;
      }
  })
}

export function addSubEduction(name, subsystem_id, education_id) {
    
    return $.ajax({
        url: `/sub_education`, //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          rank: 1000,
          subsystem_id: subsystem_id,
          parent_id: education_id
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          
          return;
        },
        error: (error) => {
          alert('Unable to add sub educations. Please try your request again')
          
          return;
        }
      })
}

export function updateSubEducation(id, name, rank, education_id) {
    
    return $.ajax({
        url: `/sub_education/${id}`, //TODO: update request URL
        type: "PUT",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          rank: rank,
          parent_id: education_id
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            alert(result.message)
            
            return result.data;
        },
        error: (error) => {
          alert('Unable to add sub educations. Please try your request again')
          
          return;
        }
      })
}

export function deleteSubEducation(id) {
    
    return $.ajax({
        url: `/sub_education/${id}`, //TODO: update request URL
        type: "DELETE",
        success: (result) => {
            alert("Successfully deleted")
            
            return;
        },
        error: (error) => {
            alert('Unable to Delete systems. Please try your request again')
            
            return;
        }
    })
}