import React from 'react';

import Register from '../Register'
import UserView from './UserView'
import {
  useStateValue
} from '../../Data/StateProvider'

function ManageUsers () {
  const [{
    isAdmin
  }, ] = useStateValue();
  console.log(isAdmin);
  console.log("isAdmin");
    return (
        <div>
        {
          isAdmin ? (
          <>
            <UserView/>
            <Register/>
          </>
          ):
          <h1>Unfortunately You dont have access to this</h1>
        }
        </div>
    )
}

export default ManageUsers;