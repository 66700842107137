import $ from 'jquery';
import client from '../services/Client';

export function getUsers (){
    return $.ajax({
        url: '/users', //TODO: update request URL
        type: "GET",
        dataType: 'json',
        contentType: 'application/json',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(client.LOCAL_STORAGE_KEY)}`,
        },
        data: JSON.stringify({}),
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            return result.data;
        },
        error: (error) => {
            alert(error.responseJSON.message)
            return null;
        }
    })
}

export function deleteUsers(id) {
    if (window.confirm('are you sure you want to delete the User?')) {
        return $.ajax({
            url: `/users/${id}`, //TODO: update request URL
            type: "DELETE",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(client.LOCAL_STORAGE_KEY)}`,
            },
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
                return;
            },
            error: (error) => {
                alert(error.responseJSON.message)
                return;
            }
        })
    }
}