import $ from 'jquery';
import client from '../services/Client';

export function register(email, name, password, admin) {
    return $.ajax({
        url: '/register', //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
            email: email,
            name: name,
            password: password,
            admin: admin
        }),
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            client.setToken(result.auth_token)
            alert(result.message)
        },
        error: (error) => {
          if(error !== undefined || error.message !== null)
            alert(error.responseJSON.message)
          else
            alert("Unable to Add the User<br/> Refresh the page!!!")
        }
    })
}

export function login(email, password){
    return $.ajax({
        url: '/login',
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          email: email,
          password: password,
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          client.setToken(result.auth_token)
          alert(result.message)
          return true;
        },
        error: (error) => {
          if(error.message !== undefined)
          alert(error.message)
          else
            alert("Unable to Authenicate \nRefresh the page!!!")
          return false;
        }
      })
}