import $ from 'jquery';


export function getTimetable(date, id) {
    
    var url = `/timetable?parentId=${id}&time=${date}`
    return $.ajax({
        url: url, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load specialtys. Please try your request again')
            
            return null;
        }
    })
}

export function addTimetable(data) {
    
    return $.ajax({
        url: `/timetable`, //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            
            return;
        },
        error: (error) => {
          if(error.responseJSON) alert(error.responseJSON.message)
          else alert('Unable to add sub educations. Please select a proper date and/or time')
          
          return;
        }
      })
}

export function updateTimetable(id, data) {
  return $.ajax({
    url: `/timetable/${id}`, //TODO: update request URL
    type: "PUT",
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify(data),
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    success: (result) => {
        alert(result.message)
        return;
    },
    error: (error) => {
      if(error.responseJSON) alert(error.responseJSON.message)
      else alert('Unable to update sub educations. Please select a proper date and/or time')
      return;
    }
  })
}

export function getOneTimetable(id) {
  return $.ajax({
      url: `/timetable/${id}`, //TODO: update request URL
      type: "GET",
      success: (result) => {
                    
          return result.data;
      },
      error: (error) => {
        console.log(error);
          alert('Unable to Obtain timetable. Please try your request again')
          
          return null;
      }
  })
}

export function deleteTimetable(id) {
    
  return $.ajax({
      url: `/timetable/${id}`, //TODO: update request URL
      type: "DELETE",
      success: (result) => {
          alert("Successfully deleted")
          
          return ;
      },
      error: (error) => {
          alert('Unable to Delete timetable. Please try your request again')
          
          return null;
      }
  })
}