import React, {
    useState,
    useEffect
} from 'react';

import ViewItems from "./ViewItems"

import '../../Stylesheets/Dashboard.css';
import {
    addEduction,
    deleteEducation,
    getEducation,
    updateEducation
} from '../../API/Education';
import {
    getOneSubSystems
} from '../../API/Subsystem';
import FilterList from '../../Components/FilterList/FilterList';
import {
    useStateValue
} from '../../Data/StateProvider'
import {
    getSubEducation,
  } from '../../API/SubEducation';
  import {
    getClasses,
  } from '../../API/Class';

const  AddEducation = props => {

    const [{
        selectedSystem,
        selectedEducation,
        isAdmin,
    }, dispatch] = useStateValue();

    const [name, setName] = useState("")
    const [educations, setEducations] = useState([])
    const [parent, setParent] = useState({})

    useEffect(() => {
        (async function(){
            if(selectedSystem._id) {
                var educations = await getEducation(selectedSystem._id)
                var result = await getOneSubSystems(selectedSystem._id)
                setParent(result.data)
                setEducations(educations.data)
            }
        })();
    }, [selectedSystem])


    const submitEducation = async (event) => {
        event.preventDefault();
        var educations = await addEduction(name, selectedSystem._id)
        document.getElementById("add-educations-form").reset();
        setEducations(educations.data)
    }

    const handleChange = (event) => {
        setName(event.target.value)
    }


    const updateChild = async (id, rank, name) => {
        await updateEducation(id, rank, name, selectedSystem._id)
        var educations = await getEducation(selectedSystem._id)
        setEducations(educations.data)
    }

    const deleteAction = async (id) => {
        if (window.confirm('are you sure you want to delete the Education?')) {
            await deleteEducation(id)
            var educations = await getEducation(selectedSystem._id);
            setEducations(educations.data)
        }
    }
    const setSelectedSystem = (selection) => {
        dispatch({
            type: 'SET_SELECTED_EDUCATION',
            item: selection
        })
    }
    const contentTypeClickHandler = async(contentType) => {
        setSelectedSystem(contentType)
            if(contentType._id) {
                var sub_educations = await getSubEducation(contentType._id)
                var classes = await getClasses(contentType._id)
                if(sub_educations.data.length > 0){
                    props.setCategories({
                        'name': 'Sub-Education type',
                        '_id': 'SUB-CATEGORY'
                      })
                }else{
                    if(classes.data.length > 0){
                    props.setCategories({
                        'name': 'level',
                        '_id': 'CLASS'
                      })
                }
                }
                
            }
    }

    return (
        <div className="add-items">
            <h3 className="parentName">{parent.name}</h3>
            <FilterList activeFilters={[selectedEducation]}
                        filters={educations}
                        clicked={contentTypeClickHandler }/>
            {isAdmin && (<><ViewItems 
            items={educations}
            deleteAction = {deleteAction}
            updateChild={updateChild}
            />
            <div id="add-items__form">
            <form className="add-items__form-view" id="add-educations-form" onSubmit={submitEducation}>
                <label>
                <span>Education Type</span>
                <input type="text" name="name" onChange={handleChange}/>
                </label>
                <input type="submit" className="button" value="Submit" />
            </form>
            </div></>)}
        </div>
    );
    
}

export default AddEducation;