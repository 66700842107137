import React from 'react';

import './Footer.css';

const footer = (props) => {
    const navTo = (uri)=>{
        window.location.href = window.location.origin + uri;
      }
    return (<React.Fragment>
        <footer className="Footer">
            <ul className="Footer__list list-style-none">
                <li><span>Ministère des Enseignements Secondaires</span></li>
                <li><span>Lieu : Lycée Technique Bilingue – Yaoundé</span></li>
                <li><span>Tél: +237 222 22 38 43</span></li>
                <li><span>Fax: +237 222 22 27 11</span></li>
                <li><span>E-mail: difodes.minesec@gmail.com</span></li>
            </ul>
            {/* <ul className="Footer__list list-style-none">
                <li><span onClick={() => {navTo('')}}>Home</span></li>
                <li><span onClick={() => {navTo('/dashboard')}} >Dashboard</span></li>
                <li><span onClick={() => {navTo('/login')}}>Login</span></li>
            </ul> */}
        </footer>
        <div className="Copyright">
            <p>MINISTRY OF SECONDARY EDUCATION &copy; 2020 <span className='text-orange'>MINSEC</span></p>
        </div>
    </React.Fragment>)
}

export default footer