import './TabItems.scss';
import TabItem from './TabItem/TabItem';

const tabItems = props => {
    return <div className='TabItems'>
        {props.tabs.map(tab => {
            let tabItem = null
            let newTab = (props.selectedChoice !== "Normal" && Array.isArray(tab))? tab[0]:tab
            console.log(props.activeTab)
            if (Array.isArray(newTab)) {
                tabItem = (
                    <TabItem key={newTab[0]._id}
                             changeTab={props.changeTab}
                             subTabs={newTab[1]}
                             active={props.activeTab.name === tab[0].name}
                             activeTab={props.activeTab}>
                        {newTab[0]}
                    </TabItem>
                );
            } else {
                tabItem = (
                    <TabItem
                        key={newTab._id}
                        changeTab={() => props.changeTab(newTab)}
                        active={props.activeTab.name === newTab.name}>
                        {newTab}
                    </TabItem>)
            }
            return tabItem;
        })}
    </div>
}

export default tabItems;