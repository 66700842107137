import React, {
    useState,
    useEffect
  } from 'react';
  
import '../../Stylesheets/Dashboard.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    addTimetable, updateTimetable, getOneTimetable
} from '../../API/Timetable';
import {
  useStateValue
} from '../../Data/StateProvider'
import TimetableView from '../../TimetableView/TimetableView';

function AddTimetable (){

    const [{
        selectedSystem,
        selectedEducation,
        selectedSubEducation,
        selectedExam,
      }, ] = useStateValue();

    const [name, setName] = useState("")
    const [link, setLink] = useState("")
    const [date, setDate] = useState(new Date())
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const [signupTime, setSignupTime] = useState("")
    const [studio, setStudio] = useState("")
    const [selectedDate, setSelectedDate] = useState({})
    const [parent, setParent] = useState('')
    const [edit, setEdit] = useState(false)
    const [_id, _setId] = useState('')


    useEffect(() => {
      (async function(){
          if(selectedExam._id) {
            // await getTimetable(selectedDate, selectedExam._id)
            var subsys = typeof selectedSystem.name !== 'undefined' ? selectedSystem.name.toUpperCase() + ' / ' : ''
            var edu = typeof selectedEducation.name !== 'undefined' ? selectedEducation.name.toUpperCase() + ' / ' : ''
            var subEdu = typeof selectedSubEducation.name !== 'undefined' ? selectedSubEducation.name.toUpperCase() + ' / ' : ''
            var exams = typeof selectedExam.name !== 'undefined' ? selectedExam.name.toUpperCase() + ' / ' : ''
            var parentName = typeof selectedExam.name !== 'undefined' ? subsys+edu+subEdu+exams: ''
            setParent(parentName)
          }
      })();
  }, [selectedSystem,
    selectedEducation,
    selectedSubEducation,
    selectedExam])

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handleLinkChange = (event) => {
        setLink(event.target.value)
    }
  
    const handleStartTimeChange = (event) => {
        setStartTime(event.target.value)
    }

    const handleEndTimeChange = (event) => {
        setEndTime(event.target.value)
    }

    const handleStudioChange = (event) => {
        setStudio(event.target.value)
    }

    const submitTimetable = async (event) => {
      event.preventDefault();
      var parent_id = typeof selectedSubEducation._id === 'undefined' ? selectedEducation._id : selectedSubEducation._id
      var data = {
          "name": name,
          "link": link,
          "time": `${selectedDate} 00:02`,
          "startTime": startTime,
          "endTime": endTime,
          "signupTime": signupTime,
          "studio": studio,
          "subsystem_id": selectedSystem._id,
          "parent_id": [selectedExam._id, parent_id]
      }
      if(edit){
        await updateTimetable(_id, data)
        setEdit(false)
      }
      else await addTimetable(data)
      cancelEdit()
    }

    const cancelEdit = () => {
      setName("")
      setLink("")
      setStudio("")
      setStartTime("")
      setEndTime("")
      setEdit(false);
      document.getElementById("add-timetable-form").reset();
    }

    const getTimetableToUpdate = async (id) => {
      setEdit(true)
      var response = await getOneTimetable(id)
      setName(response.data['name'])
      setLink(response.data['link'])
      setStudio(response.data['studio'])
      setStartTime(response.data['start_time'])
      setEndTime(response.data['end_time'])
      // setSignupTime(response.data['signup_time'])
      // setDate(response.data['time'])
      _setId(id)
      scroll()
    }

    const scroll = () => {
      const section = document.querySelector( '#add-timetable-form' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    
    
    const  handleSelect = (event) => {
        setSelectedDate(`${event.getFullYear()}-${event.getMonth() + 1}-${event.getDate()}`);
        setDate(event);
      }
    const handleDateChange = () => {}
    const handleSignupSelect = (event) => {
      setSignupTime(`${event.getFullYear()}-${event.getMonth() + 1}-${event.getDate()} ${event.getHours()}:${event.getMinutes()}`);
      setDate(event);
    }

    return (
      <div className="add-items">
        <h3 className="parentName">{String(parent)}</h3>
        <TimetableView
          selectedExam={[selectedExam]}
          showDelete={true}
          updateClicked={getTimetableToUpdate}
        />
        <div id="add-items__form">
          <form className="add-items__form-view">
            <h2>.</h2>
            <h2>.</h2>
            <h2>Add a new Shedule</h2>
            <label className="Rtable-cell">
              <span>Choose Schedule Date</span>
              <DatePicker
                placeholderText="Click to choose a date"
                selected={date}
                onSelect={handleSelect} //when day is clicked
                onChange={handleDateChange} //only when value has changed
                />
            </label>
          </form>
          <form className="add-items__form-view" id="add-timetable-form" onSubmit={submitTimetable}>
            <label>
              <span>Name</span>
              <input type="text" name="name" placeholder="Name" onChange={handleNameChange} value={name} required/>
            </label>
            <label>
              <span>Class Link</span>
              <input type="text" placeholder="class Link"  name="link" onChange={handleLinkChange} value={link} required/>
            </label>
            <label>
              <span>Start Time</span>
              <input type="text" name="start_time" placeholder="starts at(HH:MM)" onChange={handleStartTimeChange} value={startTime} required/>
            </label>
            <label>
              <span>End Time</span>
              <input type="text" name="end_time" placeholder="ends at(HH:MM)" onChange={handleEndTimeChange} value={endTime} required/>
            </label>
            <label>
              <span>Studio</span>
              <input type="text" name="studio" placeholder="studio" onChange={handleStudioChange} value={studio} required/>
            </label>
            <label>
              <span>End time for Signup</span>
                <DatePicker
                  selected={date}
                  onChange={handleSignupSelect}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm"
                  />
            </label>
            { !edit ? 
                <div style={{"display": "flex", "justify-content": "space-evenly"}} className="center__btn">
                  <input type="submit" className="button" value="Submit" />
                </div>
              :
              <div style={{"display": "flex", "justify-content": "space-evenly"}}>
                <input type="submit" value="Update" />
                <input type="reset" value="Cancel" onClick={cancelEdit} />
              </div>
            }
          </form>
        </div>
        </div>
      );
  }
  
  export default AddTimetable;