import React, {
  useState,
  useEffect
} from 'react';

import ViewItems from "./ViewItems"

import '../../Stylesheets/Dashboard.css';
import {
  addClass,
  deleteClass,
  getClasses,
  updateClass
} from '../../API/Class';
import {
  getOneEducation,
} from '../../API/Education';
import {
  getOneSubEducation,
} from '../../API/SubEducation';
import FilterList from '../../Components/FilterList/FilterList';
import {
  useStateValue
} from '../../Data/StateProvider'
import {
  getLevel,
} from '../../API/Level';
import {
  getSubject,
} from '../../API/Subject';

const AddClass = (props) =>{

  const [{
    selectedSystem,
    selectedEducation,
    selectedSubEducation,
    selectedClass,
    isAdmin,
  }, dispatch] = useStateValue();

  const [name, setName] = useState("")
  const [classes, setClasses] = useState([])
  const [parent, setParent] = useState({})

  useEffect( ()=> {
    (async function() {
      if(typeof selectedSubEducation._id === 'undefined') {
        if(typeof selectedEducation._id !== 'undefined') {
          var result = await getOneEducation(selectedEducation._id)
          var classes = await getClasses(selectedEducation._id)
          setClasses(classes.data)
          setParent(result.data)
        }
      } else {
        var result2 = await getOneSubEducation(selectedSubEducation._id)
        var classes2 = await getClasses(selectedSubEducation._id)
        setClasses(classes2.data)
        setParent(result2.data)
      }
    })();
  }, [selectedEducation, selectedSubEducation])


  const submitClass = async (event) => {
    event.preventDefault();
    var classes = await addClass(name, 0, selectedSystem._id, selectedEducation._id, selectedSubEducation === {}?null:selectedSubEducation._id)
    setClasses(classes.data)
    document.getElementById("add-class-form").reset();
  }

  const handleChange = (event) => {
    setName(event.target.value)
  }


  const updateChild = async (id, rank, name) => {
    await updateClass(id, name, rank)
    var classes = await getClasses(selectedSubEducation._id?selectedSubEducation._id:selectedEducation._id)
    setClasses(classes.data)
  }

  const deleteAction = async (id) => {
    if (window.confirm('are you sure you want to delete the Education?')) {
      await deleteClass(id)
      var classes = typeof selectedSubEducation._id !== 'undefined' ?await getClasses(selectedSubEducation._id): await getClasses(selectedEducation._id)
      setClasses(classes.data)
    }
  }

  const setSelectedClass = (selection) => {
    dispatch({
      type: 'SET_SELECTED_CLASS',
      item: selection
    })
  }

  const contentTypeClickHandler = async(contentType) => {
    setSelectedClass(contentType)
        if(contentType._id) {
          var levels = await getLevel(contentType._id)
          var subjects = await getSubject(contentType._id)
            if(levels.data.length > 0){
                props.setCategories({
                    'name': 'Class',
                    '_id': 'CATEGORY'
                  })
            }else{
              if(subjects.data.length > 0){
                props.setCategories({
                    'name': 'Subject',
                    '_id': 'SUBJECT'
                  })
            }
            }
        }
}

    return (
      <div className="add-items">
        <h3 className="parentName">{parent.name}</h3>
        <FilterList activeFilters={[selectedClass]}
                    filters={classes}
                    clicked={contentTypeClickHandler }/>
        {isAdmin && (<><ViewItems 
          items={classes}
          deleteAction = {deleteAction}
          updateChild={updateChild}
        />
        <div id="add-items__form">
          <form className="add-items__form-view" id="add-class-form" onSubmit={submitClass}>
            <label>
              <span>Class</span>
              <input type="text" name="name" onChange={handleChange}/>
            </label>
            <input type="submit" className="button" value="Submit" />
          </form>
        </div></>)}
      </div>
    );
}

export default AddClass;