import React, { Component } from 'react';
import './SystemItem.scss';

class TabItem extends Component {

    render() {
        return (
            <div onClick={this.props.changeTab} className={['SystemItem', this.props.active ? 'SystemItem--active' : null].join(' ')}>
                <span>{this.props.children.name}</span>
            </div>
        );
    }
}


export default TabItem;