import React, {
	useState,
	useEffect,
	useCallback
  } from 'react';

import '../Stylesheets/Dashboard.css';
import './TimetableView.css'
import DatePicker from "react-datepicker";
import DateControl from "../DateControl/DateControl"
import "react-datepicker/dist/react-datepicker.css";
import { deleteTimetable, getTimetable } from '../API/Timetable';

function TimetableView(props) {
	const [timetables, setTimetables] = useState([])
	const [date, setDate] = useState(new Date())
	const [selectedDate, setSelectedDate] = useState('')

	const getTimetables = useCallback(async (new_date) => {
		var timetables = []
		for(var exam of props.selectedExam){
			// console.log(exam)
			var timetable = exam ? await getTimetable(new_date, exam._id): []
			// console.log(timetable.data)
			timetables = timetables.concat(timetable.data)
		}
		// console.log(timetables)
		setTimetables(timetables)
	}, [props.selectedExam])

	useEffect(() => {
		getTimetables(setNewDate())
	}, [getTimetables]);

	const handleSelect = (event) => {
		setSelectedDate(setNewDate(event))
		setDate(event)
		getTimetables(setNewDate(event))
	}
	const setNewDate = (new_date) => {
		setDate(new_date)
		if (typeof new_date == 'undefined') {
			new_date = new Date()
		}
		return `${new_date.getFullYear()}-${new_date.getMonth() + 1}-${new_date.getDate()} 00:02`
	}
	const handleDateChange = (event) => {}

	const deleteAction = async (id) => {
		if (window.confirm('are you sure you want to delete the Video?')) {
			await deleteTimetable(id)
			getTimetables(selectedDate)
		}
	}
	return (
		<div className="view-user-holder">
			<DateControl selected={date}
				onSelect={handleSelect} // function which executes when button is clicked
				showFrench = {props.showFrench}
				onChange={handleDateChange}
			/>
			<table>
			{ timetables && <div>
				{ !props.showDelete ? (
					<tr className="Rtable Rtable--6cols table-title">
					  <th className="Rtable-cell table-title-item"> Name / Nom</th>
					  <th className="Rtable-cell table-title-item"> Date / Date</th>
					  <th className="Rtable-cell table-title-item"> Time / Temps</th>
					  <th className="Rtable-cell table-title-item"> Venue / Venu</th>
					  <th className="Rtable-cell table-title-item"> Register / S'inscrire</th>
					</tr>):(
					<tr className="Rtable Rtable--6cols table-title">
						<th className="Rtable-cell table-title-item">Name / Nom</th>
						<th className="Rtable-cell table-title-item">Date / Date</th>
						<th className="Rtable-cell table-title-item">Time / Temps</th>
						<th className="Rtable-cell table-title-item">Hall / Sale</th>
						<th className="Rtable-cell table-title-item">Register / S'inscrire</th>
						<th className="Rtable-special-width table-title-item">Actions / Actions</th>
					</tr>)
				}
				</div>
			}
			{ timetables.length > 0 && timetables.map((item, ind) => (
				!props.showDelete ? (
					<tr key={item['_id']} className="view-user-holder__list-item Rtable Rtable--5cols">
						<td className="Rtable-cell table-item"> {item['name']}</td>
						<td className="Rtable-cell table-item"> {item['time'].split('00:')[0]}</td>
						<td className="Rtable-cell table-item"> {`${item['start_time']} - ${item['end_time']}`}</td>
						<td className="Rtable-cell table-item"> {item['studio']}</td>
						{ !item["signup"] ? (
							<td className="Rtable-cell table-item"> <span style={{color: "red"}}>Closed</span></td>)
							:
							<td className="Rtable-cell table-item">
								<a href={item['link']} target="_blank" rel="noopener noreferrer">Click here/Cliquez ici</a>
								<div className="signup-time">Latest/Avant {item['signup_time']}</div>
							</td>
						}
					</tr>
					):(
						<tr key={item['_id']} className="Rtable Rtable--6cols view-user-holder__list-item">
							<td className="Rtable-cell table-item"> {item['name']}</td>
							<td className="Rtable-cell table-item"> {item['time'].split('00:')[0]}</td>
							<td className="Rtable-cell table-item"> {`${item['start_time']} - ${item['end_time']}`}</td>
							<td className="Rtable-cell table-item"> {item['studio']}</td>
							{ !item["signup"] ? (
								<td className="Rtable-cell table-item"><span style={{color: "red"}}>Closed</span></td>) :
								<td className="Rtable-cell table-item">
									<a href={item['link']} target="_blank" rel="noopener noreferrer">Click here/Cliquez ici</a>
							  		<div className="signup-time">Latest/Avant {item['signup_time']}</div>
								</td>
							}
							<td className="Rtable-special-width table-item">
							  <div className="edit-delete-space">
								<svg style={{fill: "red"}} className="icon-editing-timetable icon-editing-delete" onClick={() => deleteAction(item._id)}>
									<use xlinkHref="./icons/symbol-defs.svg#icon-bin"></use>
								</svg>

								{!item["signup"] ? null: //this line displays action button if signup date is still available
									<svg style={{fill: "blue"}} className="icon-editing-timetable icon-editing-delete" onClick={() => props.updateClicked(item._id)}>
									<use xlinkHref="./icons/symbol-defs.svg#icon-pencil"></use>
									</svg>
								}

							  </div>
							</td>
					  </tr>
					)
				)
			)}
			{ !timetables && <div id="view-user-items" className="view-user-holder__view-user-items">
					<h2>No timetables added</h2>
				</div>
			}
			</table>
		</div>
	);

}
export default TimetableView
