import React from 'react';

import AddSystem from './DashboardComponents/AddSystem'
import AddEducation from './DashboardComponents/AddEducation'
import AddSubCategory from './DashboardComponents/AddSubEducation'
import AddClass from './DashboardComponents/AddClass'
import AddLevel from './DashboardComponents/AddLevel'
import AddSpecialty from './DashboardComponents/AddSpecialty'
import AddSubject from './DashboardComponents/AddSubject'
import AddVideo from './DashboardComponents/AddVideo'
import { useStateValue } from '../Data/StateProvider';
import AddVideoPromo from './DashboardComponents/AddVideoPromo';
import AddExams from './DashboardComponents/AddExams';
import AddTimetable from './DashboardComponents/AddTimetable';
import AddNews from './DashboardComponents/AddNews';
import ManageUsers from './DashboardComponents/ManageUsers';
// import AddVideo from './AddVideo'
// import AddRevisionVideo from './AddRevisionVideo'
// import ManageTeachers from './ManageTeachers'
// import AddExam from './AddExam'
// import AddExamLevel from './AddExamLevel'

const FormViewController = props => {

  const [{
    selectedCategory
  }, ] = useStateValue();

    if (selectedCategory === 'SYSTEMS'){
        return (
            <AddSystem setCategories={props.setNormalCategories} test={props.test} />
        );
    }
    if (selectedCategory === 'EDUCATION'){
        return (
            <AddEducation setCategories={props.setNormalCategories} test={props.test}/>
        );
    }
    if (selectedCategory === 'SUB-CATEGORY'){
        return (
            <AddSubCategory setCategories={props.setNormalCategories} test={props.test}/>
        );
    }
    if (selectedCategory === 'CLASS'){
        return (
            <AddClass setCategories={props.setNormalCategories} test={props.test}/>
        );
    }
    if (selectedCategory === 'CATEGORY'){
        return (
            <AddLevel setCategories={props.setNormalCategories} test={props.test}/>
        );
    }
    if (selectedCategory === 'EXAMS'){
        return (
            <AddExams setCategories={props.setNormalCategories}/>
        );
    }
    if (selectedCategory === 'TIMETABLE'){
        return (
            <AddTimetable />
        );
    }
    if (selectedCategory === 'NEWS'){
        return (
            <AddNews />
        );
    }
    if (selectedCategory === 'SPECIALTY'){
      return (
          <AddSpecialty setCategories={props.setNormalCategories}/>
      );
    }
    if (selectedCategory === 'SUBJECT'){
        return (
            <AddSubject setCategories={props.setNormalCategories}/>
        );
    }
    if (selectedCategory === 'VIDEO'){
        return (
            <AddVideo />
        );
    }
    if (selectedCategory === 'VIDEO NEWS'){
      return (
          <AddVideoPromo />
      );
  }
    // if (selectedCategory === 'EXAMS'){
    //     return (
    //         <AddExam />
    //     );
    // }
    // if (selectedCategory === 'EXAM-CATEGORY'){
    //     return (
    //         <AddExamLevel />
    //     );
    // }
    // if (selectedCategory === "REVISION-VIDEO"){
    //     return (
    //         <AddRevisionVideo />
    //     );
    // }
    if (selectedCategory === 'USERS'){
        return (
            <ManageUsers/>
        );
    }
    return (
        <AddSystem setCategories={props.setNormalCategories}/>
    );
}

export default FormViewController;