import React, {
  useState,
  useEffect
} from 'react';

import ViewItems from "./ViewItems"

import '../../Stylesheets/Dashboard.css';
import {
  addSubEduction,
  getSubEducation,
  updateSubEducation,
  deleteSubEducation
} from '../../API/SubEducation';
import {
  getOneEducation
} from '../../API/Education'
import FilterList from '../../Components/FilterList/FilterList';
import {
  useStateValue
} from '../../Data/StateProvider'
import {
  getClasses,
} from '../../API/Class';


const AddSubCategory = (props) => {

  const [{
    selectedSystem,
    selectedEducation,
    selectedSubEducation,
    isAdmin
  }, dispatch] = useStateValue();

  const [name, setName] = useState("")
  const [sub_categories, setSubCategories] = useState([])
  const [parent, setParent] = useState({})

  useEffect(() => {
    (async function(){
        if(typeof selectedEducation._id !== 'undefined' ) {
            console.log(selectedEducation._id)
            var sub_educations = await getSubEducation(selectedEducation._id)
            setSubCategories(sub_educations.data)
            if(sub_educations.data.length > 0) {
              var result = await getOneEducation(selectedEducation._id)
              setParent(result.data)
            }
        }
        })();
    }, [selectedEducation])

  const submitSubCategory = async (event) => {
    event.preventDefault();
    var sub_categories = await addSubEduction(name, selectedSystem._id, selectedEducation._id)
    setSubCategories(sub_categories.data)
    document.getElementById("add-categories-form").reset();
  }

  const handleChange = (event) => {
    setName(event.target.value)
  }

  const updateChild = async (id, rank, name) => {
    await updateSubEducation(id, name, rank, selectedEducation._id)
    var sub_categories = getSubEducation(selectedEducation._id)
    setSubCategories(sub_categories.data)
  }

  const deleteAction = async (id) => {
    if (window.confirm('are you sure you want to delete the Education?')) {
      await deleteSubEducation(id)
      var sub_categories = await getSubEducation(selectedEducation._id)
      setSubCategories(sub_categories.data)
    }
  }

  const setSelectedEducation = (selection) => {
    dispatch({
      type: 'SET_SELECTED_SUB_EDUCATION',
      item: selection
    })
  }

  const contentTypeClickHandler = async(contentType) => {
    setSelectedEducation(contentType)
        if(contentType._id) {
            var classes = await getClasses(contentType._id)
                if(classes.data.length > 0){
                props.setCategories({
                    'name': 'level',
                    '_id': 'CLASS'
                  })
            }
            
            
        }
}

    return (
      <div className="add-items">
        <h3 className="parentName">{parent?parent.name:''}</h3>
        <FilterList activeFilters={[selectedSubEducation]}
                    filters={sub_categories}
                    clicked={contentTypeClickHandler }/>
        {isAdmin && (<><ViewItems 
          items={sub_categories}
          deleteAction = {deleteAction}
          updateChild={updateChild}
        />
        <div id="add-items__form">
          <form className="add-items__form-view" id="add-categories-form" onSubmit={submitSubCategory}>
            <label>
              <span>Stream Of Education</span>
              <input type="text" name="name" onChange={handleChange}/>
            </label>
            <input type="submit" className="button" value="Submit" />
          </form>
        </div></>)}
      </div>
    );
}

export default AddSubCategory;