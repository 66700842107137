import React, {
    useState,
    useEffect
} from 'react';


import ViewItems from "./ViewItems"

import '../../Stylesheets/Dashboard.css';
import {
    addSubsystem,
    deleteSubsystem,
    getSubSystems,
    updateSubsystem
} from '../../API/Subsystem';
import FilterList from '../../Components/FilterList/FilterList';
import {
    useStateValue
} from '../../Data/StateProvider'
import {
    getEducation,
} from '../../API/Education';

const AddSystem = props => {
    const [{
        selectedSystem, isAdmin,
    }, dispatch] = useStateValue();

    const [name, setName] = useState("")
    const [rank, ] = useState(0)
    const [systems, setSystems] = useState([])

    useEffect(() => {
        getSystems();
    }, [])

    const getSystems = async () => {
        var systems = await getSubSystems()
        setSystems(systems.data)
    }

    const updateChild = async (id, rank, name) => {
        var systems = await updateSubsystem(id, rank, name)
        setSystems(systems.data)
    }


    const submitSystem = async (event) => {
        event.preventDefault();
        var systems = await addSubsystem(rank, name)
        document.getElementById("add-systems-form").reset();
        setSystems(systems.data)
        document.getElementById("add-systems-form").reset();
    }


    const deleteAction = async (id) => {
        if (window.confirm('are you sure you want to delete the System?')) {
            await deleteSubsystem(id)
            var systems = await getSubSystems()
            setSystems(systems.data)
        }
    }

    const handleChange = (event) => {
        setName(event.target.value)
      }

    const setSelectedSystem = (selectedSystem) => {
        dispatch({
            type: 'SET_SELECTED_SYSTEM',
            item: selectedSystem
        })
    }
    const contentTypeClickHandler = async(contentType) => {
        setSelectedSystem(contentType)
            if(contentType._id) {
                var educations = await getEducation(contentType._id)
                if(educations.data.length > 0){
                    props.setCategories({
                        'name': 'Education Type',
                        '_id': 'EDUCATION'
                      })
                }
            }
    }
    return (
        <div className="add-items">
            <FilterList activeFilters={[selectedSystem]}
                        filters={systems}
                        clicked={contentTypeClickHandler }/>
            {isAdmin && (<><ViewItems 
            items={systems}
            deleteAction = {deleteAction}
            getSystems={getSystems}
            updateChild={updateChild}
            />
            <div id="add-items__form">
            <h2>Add a New System Of Education</h2>
            <form className="add-items__form-view" id="add-systems-form" onSubmit={submitSystem}>
                <label>
                <span>System Of Education</span>
                <input type="text" name="name" onChange={handleChange}/>
                </label>
                <input type="submit" className="button" value="Submit" />
            </form>
            </div></>)}
        </div>
    );
}

export default AddSystem;