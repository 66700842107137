import './FilterList.scss';
import FilterModal from '../FilterModal/FilterModal';

const filterList = props => {
    return <div className='FilterList'>
        {props.filters.map(filter =>
            <FilterModal key={typeof filter === "string"? filter:filter._id}
                         active={typeof filter === 'string' ? props.activeFilters.includes(filter): props.activeFilters[0]._id === filter._id}
                         clicked={() => props.clicked(filter)}>
                {typeof filter === "string"? filter:filter.name}
            </FilterModal>)}
    </div>
}

export default filterList;