import React, { Component } from 'react';
import {register} from '../API/Authentication'
// import ReactMixin from 'react-mixin';
// import Auth from '../services/AuthService'

import '../Stylesheets/Auth.css';
import '../Stylesheets/Loading.css';

class Register extends Component {
    constructor(props){
        super();
        this.state = {
          email: "",
          name: "",
          password: "",
          admin: "",
          token: "",
          registerInProgress: false,
          shouldRedirect: false,
        }
      }

    handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
    }

    submitRegister = (event) => {
        this.setState({ registerInProgress: true });
        event.preventDefault();
        // console.log(`${this.state.email + this.state.password + this.state.admin}`)
        register(this.state.email, this.state.name, this.state.password, this.state.admin)
        this.setState({
            admin: "",
            registerInProgress: false,
        })
        document.getElementById("auth-form").reset();
      }

    render() {
        return (
            <div className="auth-items">
                <div id="auth-items__form">
                    <h2 id="auth-header">Register Here</h2>
                    <form className="auth-items__form-view" id="auth-form" onSubmit={this.submitRegister}>
                        <label className='email'>
                            <input type="email" placeholder="email" name="email" onChange={this.handleChange} required/>
                        </label>
                        <label className='name'>
                            <input type="text" placeholder="name" name="name" onChange={this.handleChange} required/>
                        </label>
                        <label className='password'>
                            <input type="password" name="password" placeholder="Password" onChange={this.handleChange} required/>
                        </label>
                        <label className='admin'>
                            <input type="checkbox" name="admin" onChange={this.handleChange}/>
                            <span>Admin</span>
                        </label>
                        {
                        this.state.registerInProgress ? (
                        <div className='loader' />
                        ) : (
                        <input type="submit" className="auth-button" value="Register" />
                        )
                        }
                    </form>
                </div>
            </div>
        );
    }
}

export default Register;