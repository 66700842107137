import React, {Component} from 'react';
import './SideMenu.scss';
import SideMenuItem from "./SideMenuItem/SideMenuItem";

class SideMenu extends Component {
    // state = {
    //     showSideMenu: false
    // }
    // sideMenuToggleHandler = () => {
    //     this.setState(prevState => {
    //         return {showSideMenu: !prevState.showSideMenu}
    //     })
    // }
    render() {
        return (
            <React.Fragment>
                <div onClick={this.props.sideMenuToggleHandler} className={`SideMenuToggle ${this.props.showSideMenu ? 'toRight' : ''}`}>
                    <div className={`burger ${this.props.showSideMenu ? 'change' : ''}`}>
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                    </div>
                </div>
                <div className={['SideMenu', this.props.showSideMenu ? 'showMenu' : 'hideMenu' ].join(' ')}>
                    {this.props.items.length>0&&this.props.items.map(item => (<SideMenuItem key={item._id} activeMenuItem={this.props.acitveSubMenuItem === item._id} changeMenuLevelItem={this.props.changeMenuLevelItem} changeMenuItem={this.props.changeMenuItem} item={item}/>))}
                </div>
            </React.Fragment>
        )
    }
}

export default SideMenu;