import $ from 'jquery';


export function getSpecialty(id) {
    
    var url = id ? `/specialty?parentId=${id}` : "/specialty"
    return $.ajax({
        url: url, //TODO: update request URL
        type: "GET",
        success: (result) => {
            
            return result.data;
        },
        error: (error) => {
            alert('Unable to load specialtys. Please try your request again')
            
            return null;
        }
    })
}

export function getOneSpecialty(id) {
  return $.ajax({
      url: `/specialty/${id}`, //TODO: update request URL
      type: "GET",
      success: (result) => {
          
          return result.data;
      },
      error: (error) => {
          alert('Unable to load specialty. Please try your request again')
          
          return null;
      }
  })
}

export function addSpecialty(name, subsystem_id, class_id) {
    
    return $.ajax({
        url: `/specialty`, //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          rank: 1000,
          subsystem_id: subsystem_id,
          parent_id: class_id
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          
          return;
        },
        error: (error) => {
          alert('Unable to add sub educations. Please try your request again')
          
          return;
        }
      })
}

export function updateSpecialty(id, name, rank, class_id) {
    
    return $.ajax({
        url: `/specialty/${id}`, //TODO: update request URL
        type: "PUT",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: name,
          rank: rank,
          parent_id: class_id
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            alert(result.message)
            
            return result.data;
        },
        error: (error) => {
          alert('Unable to add sub educations. Please try your request again')
          
          return;
        }
      })
}

export function deleteSpecialty(id, class_id) {
    
    return $.ajax({
        url: `/specialty/${id}`, //TODO: update request URL
        type: "DELETE",
        success: (result) => {
            alert("Successfully deleted")
            
            return getSpecialty(class_id);
        },
        error: (error) => {
            alert('Unable to Delete systems. Please try your request again')
            
            return null;
        }
    })
}