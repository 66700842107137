import React, {
    Component
  } from 'react';
  
import '../../Stylesheets/Dashboard.css';
import {
  getSubSystems
} from '../../API/Subsystem';
import CoursesView from '../CoursesView/CoursesView';
import {
    addVideo,
    getAllVideos,
    deleteVideo,
    updateVideo
} from '../../API/Video';


class AddVideoPromo extends Component {
    constructor(props){
      super();
      this.state = {
        name: "",
        link: "",
        description: "",
        video: "",
        videos: [],
        classes: [],
        specialties: [],
        sub_categories: [],
        educations: [],
        levels: [],
        education_id: null,
        sub_category_id: null,
        class_id: null,
        level_id: null,
        specialty_id: null,
        subject_id: null,
        system_id: null,
        systems: [],
        subjects: [],
        contentTypes : ['summary', 'video'],
        semester : ['first', 'second', 'third', 'revision'],
        selectedSemester: 'first',
        selectedContentType: 'video',
        courses: ['course 1', 'course 2', 'Course 3', 'course 4', 'course 5', 'course 6', 'course 7', 'course 8', 'course 9'],
        advertVideo: {},
        playingVideo: {}
      }
    }
  
    componentDidMount() {
        this.getSystems()
    }

    getSystems = async () => {
        var systems = await getSubSystems()
        this.setState({
            systems: systems.data
        })
    }


    getVideo = async (id, semester) => {
        var videos = await getAllVideos(id, semester)
        console.log(videos)
        this.setState({
            videos: videos.data
        })
    }

    handleChange = (event) => {
        console.log(event.target.name)
        console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSystemChange = (event) => {
        console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value
        })
        this.getVideo(event.target.value, this.state.selectedSemester)
    }
  
    submitVideo = async (event) => {
      event.preventDefault();
      var data = {
          "name": this.state.name,
          "link": this.state.link,
          "subsystem_id": this.state.system_id,
          "description": this.state.description,
          "semester": this.state.selectedSemester,
          "parent_id": this.state.system_id
      }
      var videos = await addVideo(data);
      this.setState({
        videos: videos.data,
      })
    }

    deleteAction = async (id) => {
      if (window.confirm('are you sure you want to delete the Video?')) {
        await deleteVideo(id)
        this.getVideo(this.state.system_id, this.state.selectedSemester)
      }
    }

  update = async (id, name, description, link, active) => {
      console.log(id, name, description, link, active)
      await updateVideo(id, name, description, link, active);
      this.getVideo(this.state.system_id, this.state.selectedSemester)
    }
  
    render() {
      return (
        <div className="add-items">
        <form className="filter" id="filter">
          <label >
            <select name="system_id" onChange={this.handleSystemChange}>
                <option value={null}>Select an Sub-System type</option>
                {this.state.systems && this.state.systems.map((item, ind) => (
                <option key={item['_id']} value={item._id}>
                    {item.name}
                </option>
                ))}
            </select>
          </label>
        </form>
          <div id="add-items__form">
            <h2>Add a new Video</h2>
            <form className="add-items__form-view" id="add-video-form" onSubmit={this.submitVideo}>
              <label>
                <span>Name</span>
                <input type="text" name="name" onChange={this.handleChange} required/>
              </label>
              <label>
                <span>Description</span>
                <textarea
                  rows="4" cols="30"
                  type="text"
                  className="form-control form-control-lg"
                  name="description"
                  onChange={this.handleChange}
                  required
                />
              </label>
              <label>
                <span>Link</span>
                <input type="url" placeholder="https://www.youtube.com/watch?v=HbAZ6cFxCeY" pattern="https://www.youtube.com/.*" name="link" onChange={this.handleChange} required/>
              </label>
              {this.state.isUploading ? <input type="submit" className="button" value="uploading..." /> 
              :
              <input type="submit" className="button" value="Submit" />}
            </form>
          </div>
          <CoursesView advertVideo={null}
                viewType={this.state.selectedContentType}
                courses={this.state.videos}
                playingVideo={this.state.playingVideo}
                clicked={()=>{}}
                closeCourse={null}
                showDelete={true}
                delete={this.deleteAction}
                update={this.update}/>
        </div>
      );
    }
  }
  
  export default AddVideoPromo;