import './Header.scss';
import '../../Stylesheets/Loader.css'
// import { useStateValue } from '../../Data/StateProvider';

const Header = (props) => {
    // const [isLoading] = useStateValue();
    return (<header className='Header'>
        <div className="Flag">
            <div className="Flag--green">
                <p>Republique du Cameroun</p>
            </div>
            <div className="Flag--red">
                <div className=" view-holder__star-full" onClick={() => this.props.deleteAction()}>
                    <svg className="icon-star-full">
                        <use xlinkHref="./icons/symbol-defs.svg#icon-star-full"></use>
                    </svg>
                </div>
            </div>
            <div className="Flag--yellow">
                <p>Republic of Cameroon</p>
            </div>
        </div>
        <div className="flex-split Min-info">
            <p>MINISTRY OF SECONDARY EDUCATION</p>
            <p>MINISTERE DES ENSEIGNEMENTS SECONDAIRES</p>
        </div>
        {/* {isLoading? <div class="loader">
            <div class="loader__element"></div>
        </div>:<></>} */}
        <div className="flex-split Min-goal">
            <p>Distance Education</p>
            <p>Education á Distance</p>
        </div>
    </header>)
}

export default Header;