import './CourseView.css';
import React, {useState} from 'react';

function CourseView (props) {
    const [showEdit, setshowEdit] = useState(false);
    const [active, setshowChecked] = useState(props.course.active);
    const [name, setName] = useState(props.course.name);
    const [description, setDescription] = useState(props.course.description);
    const [link, setLink] = useState(props.course.link);

    const handleNameChange = (event) => {
      setName(event.target.value)
    }
    const handleDescritionChange = (event) => {
      setDescription(event.target.value)
    }
    const handleLinkChange = (event) => {
      setLink(event.target.value)
    }
    const handleActiveChange = (event) => {
      setshowChecked(!active)
    }

    const submitVideo = async (event) => {
      event.preventDefault();
      props.update(props.course._id, name, description, link, active);
      setshowEdit(false);
    }

    const onMouseEnterHandler = (event) => {
        event.preventDefault();
          var tooltipSpan = event.target.childNodes[1];
          if(tooltipSpan.id === "tooltip-span"){
            var x = event.clientX,
                y = event.clientY;
            
            tooltipSpan.style.display = "block";
            var windowWidth = window.screen.width
            var width = tooltipSpan.offsetWidth
            if((x+2+width + 50 > windowWidth)){
              x = windowWidth - (width + width/4)
            }
    
            tooltipSpan.style.top = (y+10) + 'px';
            tooltipSpan.style.left = (x) + 'px';
          }
        // console.log(tooltipSpan)
      }
    
    const onMouseLeaveHandler = (event) => {
        event.preventDefault();
        var tooltipSpan = event.target.childNodes[1];
        // var relatedTarget = typeof(event.relatedTarget) === "undefined" ? {'id': 'something'} : event.relatedTarget.childNodes[1];
        if(typeof(tooltipSpan) !== "undefined"){
          if((typeof(event.relatedTarget) === "undefined" || event.relatedTarget !== event.target)  && tooltipSpan.id === "tooltip-span"){
            tooltipSpan.style.display = "none";
          }
        }
        // console.log(tooltipSpan)
      }

      function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(': ');
        for (var i = 0; i < separateWord.length; i++) {
           separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
           separateWord[i].substring(1).toLowerCase();
        }
        return separateWord.join(' ');
     }


    return <React.Fragment>

        {(props.viewType === 'video' || props.viewType === 'vidéo') || props.isSelected ?
            <div onClick={props.clicked} className={['CourseView', props.isSelected ? 'CourseViewActive' : null].join(' ')}>
            {showEdit? (
              <>
              <div >
                <form className="CoursesView__form-view"  onSubmit={submitVideo}>
                  <label>
                    <span>Name</span>
                    <input value={name} type="text" name="name" onChange={handleNameChange} required/>
                  </label>
                  <label>
                    <span>Description</span>
                    <textarea
                      rows="4" cols="30"
                      type="text"
                      value={description}
                      className="form-control form-control-lg"
                      name="description"
                      onChange={handleDescritionChange}
                      required
                    />
                  </label>
                  <label>
                    <span>Link</span>
                    <input type="url" value={link} pattern="https://www.youtube.com/.*" name="link" onChange={handleLinkChange} required/>
                  </label>
                  <label class="toggle" for="myToggle">
                    <input class="toggle__input" name="" type="checkbox" id="myToggle" value={true} onChange={handleActiveChange} defaultChecked={active}/>
                    <div class="toggle__fill"></div>
                  </label>
                  <input type="submit" className="button" value="Update" />
                </form>
              </div>
              <div className="video-icon close"
                onClick={() => {setshowEdit(false)}}>
                  <span>x</span> 
              </div>
              </>
              )
              : (<>
                <iframe id="myIframe" title={props.course.name} src={props.course.link} allowfullscreen="allowFullScreen"
                mozallowfullscreen="mozallowfullscreen" 
                msallowfullscreen="msallowfullscreen" 
                oallowfullscreen="oallowfullscreen" 
                webkitallowfullscreen="webkitallowfullscreen"
                name="someIframe" />
            <div className="video__card-text">
                <span id="Name" data-tooltip={props.course.name} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
                    {capitalizeTheFirstLetterOfEachWord(props.course.name)}
                    <span id="tooltip-span">
                    {capitalizeTheFirstLetterOfEachWord(props.course.name)}
                    </span>
                </span>
                <span id="Date">{props.course.createdAt}</span>
            </div>
            {props.showDelete && !showEdit?(<div className={` ${'show'}`}>
            <div className="video-icon edit"
                  onClick={() => {setshowEdit(true)}}>
                    <svg className="icon-pencil">
                      <use xlinkHref="./icons/symbol-defs.svg#icon-pencil"></use>
                    </svg>  
                  </div>
                <div className="video-icon Delete" onClick={props.delete}>
                <svg className="icon-bin">
                    <use xlinkHref="./icons/symbol-defs.svg#icon-bin"></use>
                </svg>
                </div>
            </div>):console.log()}</>)
            }
            
        </div>:
                    <span onClick={props.clicked} className="CourseLink">{capitalizeTheFirstLetterOfEachWord(props.course.name)}</span>
            }
        {props.isSelected ? <div className="CloseCourseButton" onClick={props.closed}>&times;</div> : null}

    </React.Fragment>
}

export default CourseView;