import React, {
  useState,
  useEffect
} from 'react';

import ViewItems from "./ViewItems"

import '../../Stylesheets/Dashboard.css';
import {
  addExams,
  deleteExams,
  getExams,
  updateExams
} from '../../API/Exam';
import {
  getOneEducation,
} from '../../API/Education';
import {
  getOneSubEducation,
} from '../../API/SubEducation';
import FilterList from '../FilterList/FilterList';
import {
  useStateValue
} from '../../Data/StateProvider'

function AddExams (){

  const [{
    selectedSystem,
    selectedEducation,
    selectedSubEducation,
    selectedExam,
    isAdmin
  }, dispatch] = useStateValue();

  const [name, setName] = useState("")
  const [exams, setExams] = useState([])
  const [parent, setParent] = useState({})

  useEffect( ()=> {
    (async function() {
      if(typeof selectedSubEducation._id === 'undefined') {
        if(typeof selectedEducation._id !== 'undefined') {
          var result = await getOneEducation(selectedEducation._id)
          var exams = await getExams(selectedEducation._id)
          setExams(exams.data)
          setParent(result.data)
        }
      } else {
        var result2 = await getOneSubEducation(selectedSubEducation._id)
        var exams2 = await getExams(selectedSubEducation._id)
        setExams(exams2.data)
        setParent(result2.data)
      }
    })();
  }, [selectedEducation, selectedSubEducation])


  const submitClass = async (event) => {
    event.preventDefault();
    var exams = await addExams(name, 0, selectedSystem._id, selectedEducation._id, selectedSubEducation === {}?null:selectedSubEducation._id)
    setExams(exams.data)
    document.getElementById("add-class-form").reset();
  }

  const handleChange = (event) => {
    setName(event.target.value)
  }


  const updateChild = async (id, rank, name) => {
    await updateExams(id, name, rank)
    var exams = await getExams(selectedSubEducation._id?selectedSubEducation._id:selectedEducation._id)
    setExams(exams.data)
  }

  const deleteAction = async (id) => {
    if (window.confirm('are you sure you want to delete the Education?')) {
      await deleteExams(id)
      var exams = typeof selectedSubEducation._id !== 'undefined' ?await getExams(selectedSubEducation._id): await getExams(selectedEducation._id)
      setExams(exams.data)
    }
  }

  const setSelectedExam = (selection) => {
    dispatch({
      type: 'SET_SELECTED_EXAM',
      item: selection
    })
  }

  const contentTypeClickHandler = contentType => {
    setSelectedExam(contentType)
  }

    return (
      <div className="add-items">
        <h3 className="parentName">{parent.name}</h3>
        <FilterList activeFilters={[selectedExam]}
                    filters={exams}
                    clicked={contentTypeClickHandler }/>
        {isAdmin && (<><ViewItems 
          items={exams}
          deleteAction = {deleteAction}
          updateChild={updateChild}
        />
        <div id="add-items__form">
          <form className="add-items__form-view" id="add-class-form" onSubmit={submitClass}>
            <label>
              <span>Exams</span>
              <input type="text" name="name" onChange={handleChange}/>
            </label>
            <input type="submit" className="button" value="Submit" />
          </form>
        </div></>)}
      </div>
    );
}

export default AddExams;