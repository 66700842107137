import {Component} from 'react';

import './Content.scss';
import TabItems from '../../Components/TabItems/TabItems';
import SystemItem from '../../Components/SystemItems/SystemItem';
import SideMenu from '../../Components/SideMenu/SideMenu';
import FilterList from '../../Components/FilterList/FilterList';
import CoursesView from '../../Components/CoursesView/CoursesView';
import { getSubSystems } from '../../API/Subsystem';
import { getEducation } from '../../API/Education';
import { getSubEducation } from '../../API/SubEducation';
import { getClasses } from '../../API/Class';
import { getLevel } from '../../API/Level';
import { getSpecialty } from '../../API/Specialty';
import { getSubject } from '../../API/Subject';
import { getActiveVideos } from '../../API/Video';
import TimetableView from '../../TimetableView/TimetableView';
import { getExams } from '../../API/Exam';
import { getNews } from '../../API/News';

class Content extends Component {
    state = {
        contentTypesEn : ['summary', 'video'],
        selectedContentTypeEn: 'video',
        contentTypesFr : ['sommaire', 'vidéo'],
        selectedContentTypeFr: 'vidéo',
        contentTypes : ['summary', 'video'],
        selectedContentType: 'video',
        contentCategoriesEn: ['First Term', 'Second Term', 'Third Term', 'revision'],
        contentCategoriesFr: ['Trimestre 1', 'Trimestre 2', 'Trimestre 3', 'révision'],
        contentCategories: ['First Term', 'Second Term', 'Third Term', 'revision'],
        selectedContentCategory: 'First Term',
        contentTags: [],
        selectedContentTags: {},
        tabs: [],
        systems: [],
        selectedTab: '',
        selectedSystem: {},
        courses: [],
        advertVideo: {},
        sideMenuItems: [],
        selectedSubMenuItem: '',
        playingVideo: {},
        showTab: false,
        selectedClass: null,
        selectedSide:null,
        subject_id: null,
        choiceEn: ["Normal", "Live"],
        choiceFr: ["Normal", "Direct"],
        choices: ["Normal", "Live"],
        selectedChoice: "Normal",
        news: "",
        selectedTabList: {},
        showSideMenu: false,
    }

    componentDidMount() {
        this.fetchSystems();
    }

    fetchVideos = async (subjec_id, s_semester) => {
        var semesters = ["first", "second", "third", "revision"]
        var selected = s_semester ? s_semester : this.state.selectedContentCategory
        var selectedSemester =  semesters[this.state.contentCategories.indexOf(selected)]
        console.log(subjec_id)
        var videos = await getActiveVideos(subjec_id, selectedSemester)
        this.setState({
            courses: videos.data,
            advertVideo: false
        })
    }

    fetchSystems = async () => {
        var systems = await getSubSystems();
        var adVideos = systems.data.length > 0 ? await getActiveVideos(systems.data[0]._id, "first"): console.log("a failure")
        this.setState({
            systems: systems.data,
            selectedSystem: systems.data[0],
            advertVideo: true,
            courses: adVideos.data
        })
        this.fetchEducations(systems.data[0]._id)
    }

    fetchEducations = async (parentId) => {
        var educations = await getEducation(parentId);
        var educationList = [];
        var adVideos = this.state.selectedSystem ? await getActiveVideos(this.state.selectedSystem._id, "first"): console.log("a failure")
        var dicToList = {}
        for (var ed of educations.data) {
            var subEd = await getSubEducation(ed._id)
            dicToList[ed._id] = subEd.data.length > 0 ? subEd.data : [ed]
            subEd.data.length > 0 ? educationList.push([ed, subEd.data]) : educationList.push(ed);
        }
        var news = this.state.selectedSystem ? await getNews(this.state.selectedSystem._id): console.log("a failure")
        var long_news = '';
        for(var a_news of news.data){
            long_news = long_news  + '   <<>>>  ' + a_news.name 
        }
        var parent = Array.isArray(educationList[0]) ? educationList[0][1][0] : educations.data[0]
        this.setState({
            tabs: educationList,
            selectedTab: parent,
            advertVideo: true,
            showTab: false,
            courses: adVideos.data,
            news: long_news,
            selectedTabList: dicToList
        })
        this.fetchClasses(parent._id)
    }
    fetchClasses = async (parentId) => {
        var classes = await getClasses(parentId)
        var classList = [];
        for (var a_class of classes.data) {
            var level = await getLevel(a_class._id)
            level.data.length > 0 ? classList.push([a_class, level.data]) : classList.push(a_class);
        }
        var adVideos = this.state.selectedSystem ? await getActiveVideos(this.state.selectedSystem._id, "first"): console.log("a failure")
        this.setState({
            sideMenuItems: classList,
            selectedSubMenuItem: classList[0],
            showTab: false,
            advertVideo: true,
            courses: adVideos.data
        })
    }

    fetchExams = async (parent) => {
        var exams = await getExams(parent._id)
        this.setState({
            sideMenuItems: exams.data,
            selectedSubMenuItem: exams.data[0],
            selectedSide: parent
        })
    }

    fetchSpecialties = async (class_id, parentId) => {
        var specialties = parentId?await getSpecialty(parentId):{"data":[]}
        var specialty_select = specialties.data.length > 0 ? specialties.data[0]: null
        var subjects =  await getSubject(class_id, parentId, specialty_select ? specialty_select._id:null)
        subjects.data.length > 0 ? this.fetchVideos(subjects.data[0]._id): console.log("nothing worked")
        this.setState({
            contentTags: specialties.data,
            sideMenuItems: subjects.data,
            selectedContentTags: specialties.data.length > 0 ? specialties.data[0]: {},
            showTab: true,
            subject_id: subjects.data.length > 0 ? subjects.data[0]._id : null,
            selectedSubMenuItem: subjects.data.length > 0 ? subjects.data[0]._id : null
        })
    }

    changeTabHandler = tabName => {
        this.setState({selectedTab: tabName});
        console.log(tabName)
        this.state.selectedChoice === "Normal"?this.fetchClasses(tabName._id): console.log()
    }
    breadCrumClick = async ()=> {
        this.fetchClasses(this.state.selectedTab._id)
    }
    choiceClickHandler = (selection) => {
        this.setState({selectedChoice:selection})
        selection === "Normal" ? this.fetchEducations(this.state.selectedSystem._id) : console.log("")
        selection === "Normal" ? this.setState({
            advertVideo: true,
            showTab: false,
            courses: []
        }):this.setState({
            advertVideo: false,
            showTab: true,
            selectedTab:  Array.isArray(this.state.tabs[0]) ? this.state.tabs[0][0] : this.state.tabs[0],
        })
      }
    changeSystemHandler = system => {
        var type = system.name.toLowerCase().includes('english')?this.state.contentTypesEn:this.state.contentTypesFr
        var selection_categories = system.name.toLowerCase().includes('english')?this.state.contentCategoriesEn:this.state.contentCategoriesFr
        var selection_choice = system.name.toLowerCase().includes('english')?this.state.choiceEn:this.state.choiceFr
        this.setState({selectedSystem: system, contentTypes: type, selectedContentType: type[0], contentCategories:selection_categories, selectedContentCategory: selection_categories[0], choices:selection_choice, selectedChoice:selection_choice[0]});
        this.fetchEducations(system._id)
    }
    changeSideLevelHandler = (s_class, side) => {
        this.setState({selectedSide: side, selectedClass: s_class, showTab: true, selectedSubMenuItem: side._id});
        this.fetchSpecialties(s_class._id, side._id)
    }
    changeSideClassHandler = (s_class) => {
        console.log(s_class)
        if(this.state.selectedChoice === "Normal"){
            if(s_class.category === "subject"){
                this.setState({
                    subject_id: s_class._id,
                    selectedSubMenuItem: s_class._id,
                    showSideMenu: false,
                })
                this.fetchVideos(s_class._id)
            }else{
                this.setState({selectedSide: s_class, selectedSubMenuItem: s_class._id, selectedClass: s_class, showTab: true});
                this.fetchSpecialties(s_class._id)
            }
        }else{
            this.setState({
                selectedSubMenuItem: s_class._id,
                selectedSide: s_class,
                selectedClass: s_class
            })
        }
        
        
    }
    contentTypeClickHandler = contentType => {
        this.setState({selectedContentType: contentType})
    }
    contentCategoryClickHandler = contentCategory => {
        this.setState({selectedContentCategory: contentCategory, showSideMenu:false,})
        this.fetchVideos(this.state.subject_id, contentCategory)
    }
    contentTagClickHandler = async (tag) => {
        this.setState({selectedContentTags: tag})
        var subjects = await getSubject(this.state.selectedClass._id, this.state.selectedSide._id, tag._id)
        subjects.data.length > 0 ? this.fetchVideos(subjects.data[0]._id): console.log("nothing worked")
        this.setState({
            sideMenuItems: subjects.data,
            selectedSubMenuItem: subjects.data[0]._id
        })
    }
    courseClickHandler = (course) => {
        console.log(course)
        this.setState({playingVideo: course})
    }
    closeCourseHandler = () => {
        this.setState({playingVideo: {}})
    }
    sideMenuToggleHandler = () => {
        this.setState(prevState => {
            return {showSideMenu: !prevState.showSideMenu}
        })
    }

    render() {
        var levelName = this.state.selectedChoice ==="Normal" && typeof this.state.selectedSide !== "undefined" ?((this.state.selectedClass === this.state.selectedSide) ? '' : '/'+this.state.selectedSide.name):''
        return <div className='Content'>
            <SystemItem tabs={this.state.systems} activeTab={this.state.selectedSystem} changeTab={this.changeSystemHandler}/>
            <TabItems selectedChoice={this.state.selectedChoice} tabs={this.state.tabs} activeTab={this.state.selectedTab} changeTab={this.changeTabHandler}/>
            <div className="center">
                <p className="marquee">
                    <span>{this.state.news}</span>
                </p>
                <FilterList activeFilters={[this.state.selectedChoice]}
                        filters={this.state.choices}
                        clicked={this.choiceClickHandler }/>
            </div>
            <main className='MainView__content'>
                {this.state.showTab && this.state.selectedChoice ==="Normal" && this.state.selectedClass !== null ? <span className="breadcrum" onClick={()=>{this.breadCrumClick()}}>
                <svg className="breadcrum--icon-arrow-left2">
                    <use xlinkHref="./icons/symbol-defs.svg#icon-arrow-left2"></use>
                </svg>
                {"  " + this.state.selectedClass.name + levelName }</span>:<></>}
                {this.state.selectedChoice === "Normal" ? <SideMenu items={this.state.sideMenuItems} acitveSubMenuItem={this.state.selectedSubMenuItem} changeMenuLevelItem={this.changeSideLevelHandler} changeMenuItem={this.changeSideClassHandler} sideMenuToggleHandler={this.sideMenuToggleHandler} showSideMenu={this.state.showSideMenu}/>: null}
                <div className={`MainView__main-view ${this.state.showSideMenu?"": "Main__show"}`}>
                    {this.state.selectedChoice ==="Normal" ?<div className="Filters">
                        <div className="Categories">
                            {this.state.contentTypes && this.state.showTab
                                ? <FilterList activeFilters={[this.state.selectedContentType]}
                                              filters={this.state.contentTypes}
                                              clicked={this.contentTypeClickHandler }/>
                                : null }
                            {this.state.contentCategories  && this.state.showTab
                                ? <FilterList activeFilters={[this.state.selectedContentCategory]}
                                              filters={this.state.contentCategories}
                                              clicked={this.contentCategoryClickHandler}/>
                                : null}
                        </div>
                        {this.state.contentTags  && this.state.showTab
                            ? <FilterList activeFilters={[this.state.selectedContentTags]}
                                          filters={this.state.contentTags}
                                          clicked={this.contentTagClickHandler}/>
                            : null }
                    </div>:null}
                    {this.state.selectedChoice === "Normal"?<CoursesView advertVideo={this.state.advertVideo}
                                 viewType={this.state.selectedContentType}
                                 courses={this.state.courses}
                                 playingVideo={this.state.playingVideo}
                                 clicked={this.courseClickHandler}
                                 closeCourse={this.closeCourseHandler}
                                 showDelete={false}
                                 delete={()=>{}}
                                 update={()=>{}}
                                 />:
                    <TimetableView
                                selectedExam={this.state.selectedTabList[this.state.selectedTab._id]}
                                showDelete={false}
                                showFrench = {!this.state.selectedSystem.name.toLowerCase().includes('english')}
                                />}
                </div>
            </main>
        </div>
    }
}

export default Content;