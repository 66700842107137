import React, {Component} from 'react';

import './SideMenuItem.css';

class SideMenuItem extends Component {
    state = {
        showMenuDropDown: false
    }

    dropdownClickHandler = () => {
        this.setState(prevState => {
            return {showMenuDropDown: !prevState.showMenuDropDown}
        })
    }

    render() {
        const hasSubMenu = Array.isArray(this.props.item);
        const items = hasSubMenu
            ? this.props.item[1].map(subitem => <div onClick={() => this.props.changeMenuLevelItem(this.props.item[0],subitem)} className="SideMenuItem"><span>{subitem.name}</span></div>)
            : null
        return (
            <React.Fragment>
                {hasSubMenu
                    ? <div onClick={this.dropdownClickHandler}
                           className="SideMenuDropdown" >
                        <span>{this.props.item[0].name.charAt(0).toUpperCase() + this.props.item[0].name.slice(1)}</span>
                      </div>
                    : <div onClick={hasSubMenu? undefined : () => this.props.changeMenuItem(this.props.item)}  className={['SideMenuItem', this.props.activeMenuItem ? 'SideMenuItem--active' : null].join(' ')}><span>{this.props.item.name.charAt(0).toUpperCase()  + this.props.item.name.slice(1).toLowerCase()}</span></div>}
                <div className={["SideMenuDropdown--sub", this.state.showMenuDropDown? 'showMenuDropDown': 'hideMenuDropDown'].join(' ')}>
                    {items}
                </div>
            </React.Fragment>
        )
    }
}

export default SideMenuItem;