import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

// import logo from './logo.svg';
import './Stylesheets/App.css';
import FormView from './Components/Dashboard';
// import FormView from './Components/FormView';
import MainView from './containers/MainView/MainView';
import Login from './Components/Login';
import Register from './Components/Register';
import Logout from "./Components/Logout"
import PrivateRoute from './Components/PrivateRoute';
// import PrivateRoute from "./Components/PrivateRoute"


function App() {
  window.onload = function () {
    disableSelection(document.body)
  }

  function disableSelection(target) {
    if (typeof target.onselectstart != "undefined")
      target.onselectstart = function () {
        return false
      }
    else if (typeof target.style.MozUserSelect != "undefined")
      target.style.MozUserSelect = "none"
    else
      target.onmousedown = function () {
        return false
      }
    target.style.cursor = "default"
  }

  function mousedwn(e) {
    try {
      if (e.button === 2 || e.button === 3) return false;
    } catch (e) {
      if (e.which === 3) return false;
    }
  }
  document.oncontextmenu = function () {
    return false;
  }
  document.ondragstart = function () {
    return false;
  }
  document.onmousedown = mousedwn;
  
  return (
    <div className="App">
      <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap" rel="stylesheet"></link>
      {/*<Header path />*/}

      <Router>
        <Switch>
          <Route path="/" exact component={MainView} />
          <PrivateRoute path="/dashboard" component={FormView} />
          <Route path="/login" component ={Login} />
          <Route path="/logout" component={Logout} />
          <Route component={MainView} />
        </Switch>
      </Router>
      {/*<Footer />*/}
    </div>
  );
}

export default App;